
import {Component, Mixins} from 'vue-property-decorator';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import ViewInterview from '@/view/content/InterviewList.vue';
import {api} from '@/api';
import MakeToast from '../content/mixin/MakeToast.vue';
import VueApexCharts from 'vue-apexcharts';
import {IInterview, IgetInterviewsQuery, ILabel} from '@/interfaces/exam';

@Component({
	components: {
		ViewInterview,
		VueApexCharts
	}
})
export default class App extends Mixins(MakeToast) {
	readonly NUM_OF_INTERVIEW_TO_ANALYSIS: number = 100;

	public interviewListWithStatusDone: Array<IInterview> | null = null;
	public donutOptions = {
		chart: {
			width: 380,
			type: 'donut'
		},
		plotOptions: {
			pie: {
				customScale: 1.2,
				offsetY: 40,
				donut: {
					labels: {
						show: true,
						total: {
							showAlways: true,
							show: true
						}
					}
				}
			}
		},
		labels: this.$t('DASHBOARD.ACTIVATE_STATUS_CATEGORIES'),
		legend: {
			fontSize: '13rem',
			position: 'top',
			horizontalAlign: 'left'
		}
	};
	public donutSeries: Array<number> = [];
	public chartOptions: any = {
		xaxis: {
			categories: []
		}
	};
	public series: Array<any> = [];
	public labelOptions: any = {};
	public labelSeries: Array<any> = [];

	public async created() {
		const getInterviewActivateStatus = interviewList => {
			const countInterviewStatus = (list: Array<IInterview>, status: string) => {
				return list.filter(interview => interview.interview_status === status).length;
			};
			this.donutSeries = [countInterviewStatus(interviewList, 'Done'), countInterviewStatus(interviewList, 'To Do'), countInterviewStatus(interviewList, 'In Progress')];
		};
		const getLabelsAnalysis = async (labels: Array<ILabel>) => {
			this.labelOptions = {
				chart: {
					toolbar: {
						show: false
					},
					height: 350,
					type: 'bar'
				},
				colors: labels.map(label => label.color),
				plotOptions: {
					bar: {
						columnWidth: '45%',
						distributed: true
					}
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					show: false
				},
				xaxis: {
					categories: labels.map(label => label.name),
					labels: {
						style: {
							offsetX: '12px',
							fontSize: '12px'
						}
					}
				}
			};
			this.labelSeries = [
				{
					name: 'Items',
					data: labels.map(label => label.amount_of_questions)
				}
			];
		};
		const getRecentlyInterview = interviewList => {
			this.interviewListWithStatusDone = interviewList.filter(interview => interview.interview_status === 'Done').slice(0, 3);
			const vue = this;

			this.chartOptions = {
				chart: {
					toolbar: {
						show: false
					},
					type: 'bar',
					height: 350
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '55%',
						endingShape: 'rounded'
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},
				xaxis: {
					categories: this.interviewListWithStatusDone!.map(interview => interview.name)
				},
				yaxis: {
					tickAmount: 4,
					min: 0,
					max: 100,
					title: {
						text: `% (${vue.$t('DASHBOARD.RATIO')})`
					}
				},
				fill: {
					opacity: 1
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val + `% ${vue.$t('DASHBOARD.RATIO')} `;
						}
					}
				}
			};

			const interviewDetailsList = this.interviewListWithStatusDone!.map(interview => interview.result!.details);
			const labelNameList: Array<string> = [];
			interviewDetailsList.map(details => {
				details.map(label => {
					let labelName = label.label_name;
					if (!labelNameList.includes(labelName)) {
						labelNameList.push(labelName);
					}
				});
			});

			labelNameList.forEach(labelName => {
				const singleSeries: {name: string; data: Array<number>} = {
					name: labelName,
					data: []
				};
				interviewDetailsList.map(details => {
					let isMatch = false;
					details.map(label => {
						if (label.label_name === labelName) {
							isMatch = true;
							singleSeries.data.push(Math.round(label.fully_correct_ratio * 100));
							return;
						}
					});
					if (!isMatch) {
						singleSeries.data.push(0);
					}
				});
				this.series.push(singleSeries);
			});
		};

		try {
			const query: IgetInterviewsQuery = {
				offset: 0,
				limit: this.NUM_OF_INTERVIEW_TO_ANALYSIS
			};
			const interviewList = (await api.getInterviewsWithQuery(query)).data.interviews;
			const labels = (await api.getLabels()).data;
			getInterviewActivateStatus(interviewList);
			getRecentlyInterview(interviewList);
			getLabelsAnalysis(labels);
		} catch {
			this.makeToast(this.$t('DASHBOARD.ERROR'));
		}
	}

	public mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.DASHBOARD')}]);
	}
}
