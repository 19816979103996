
import {Component, Vue} from 'vue-property-decorator';
import showdown from 'showdown';
import Prism from 'prismjs';

@Component
export default class MarkdownToHtml extends Vue {
	public markdownToHtml(markdownStr: string | null): string {
		const converter = new showdown.Converter();
		const codeRegex = /```/i;
		const strikeRegex = /~~/i;
		if (typeof markdownStr === 'string') {
			markdownStr = markdownStr.replaceAll('```\n', '```');
			while (markdownStr.includes('```')) {
				markdownStr = markdownStr.replace(codeRegex, '<pre><code>');
				markdownStr = markdownStr.replace(codeRegex, '</code></pre>');
			}
			while (markdownStr.includes('~~')) {
				markdownStr = markdownStr.replace(strikeRegex, '<del>');
				markdownStr = markdownStr.replace(strikeRegex, '</del>');
			}

			markdownStr = converter.makeHtml(markdownStr);
			const searchTag = '<code>';
			const searchEndTag = '</code>';
			let a = markdownStr!.indexOf(searchTag) + 6;
			let b = markdownStr!.indexOf(searchEndTag);

			if (a != -1 && b != -1) {
				let html = markdownStr!.slice(a, b);
				html = html.replaceAll('&amp;', '&');
				html = html.replaceAll('&lt;', '<');
				html = html.replaceAll('&gt;', '>');
				html = Prism.highlight(html, Prism.languages.javascript, 'javascript');
				let highlightedStr = markdownStr!.replace(markdownStr!.slice(a, b), html);

				return highlightedStr;
			}
			return markdownStr!;
		} else return '';
	}
}
