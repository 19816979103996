var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/admin/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"menu-logo mr-2",attrs:{"src":_vm.menuLogo}})])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAdminAccess),expression:"hasAdminAccess"}],attrs:{"to":"/admin/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-link_prepend-icon",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.DASHBOARD")))])])])]}}])}),_c('router-link',{attrs:{"to":"/admin/interviews/view"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.INTERVIEWS.INTERVIEWS"))+" ")])])])]}}])}),_c('router-link',{attrs:{"to":"/admin/questions/view"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU.QUESTIONS.QUESTIONS"))+" ")])])])]}}])}),_c('router-link',{attrs:{"to":"/admin/labels"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.LABELS.LABELS")))])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAdminAccess),expression:"hasAdminAccess"}],attrs:{"to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-link_prepend-icon",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.USERS.USERS")))]),_c('i',{staticClass:"fas fa-users ml-2"})])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }