const i18nService = {
	defaultLanguage: 'en',

	languages: [
		{
			lang: 'en',
			name: 'English',
			flag: process.env.BASE_URL + 'media/svg/flags/002-united-states.svg'
		},
		{
			lang: 'ch',
			name: '中文',
			flag: process.env.BASE_URL + 'media/svg/flags/001-taiwan.png'
		},
		{
			lang: 'es',
			name: 'Español',
			flag: process.env.BASE_URL + 'media/svg/flags/003-spain.svg'
		}
	],

	/**
	 * Keep the active language in the localStorage
	 * @param lang
	 */
	setActiveLanguage(lang: string): void {
		localStorage.setItem('language', lang);
	},

	/**
	 * Get the current active language
	 * @returns {string | string}
	 */
	getActiveLanguage(): string {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const lang = localStorage.getItem('language')!;
		if (
			this.languages.find(val => {
				return val.lang === lang;
			})
		) {
			return lang;
		} else {
			return this.defaultLanguage;
		}
	}
};

export default i18nService;
