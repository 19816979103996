
import {Component, Ref, Mixins} from 'vue-property-decorator';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import {IUserProfileCreate} from '@/interfaces';
import {dispatchCreateUser} from '@/core/services/store/admin/actions';
import MakeToast from '@/view/content/mixin/MakeToast.vue';

@Component
export default class CreateUser extends Mixins(MakeToast) {
	private fullName = '';
	private email = '';
	private password1 = '';
	private password2 = '';
	private isSuperuser = false;
	private isLoading = false;
	private allValid: Array<boolean> = [];

	private vFields: any = {
		name: {
			is_valid: null,
			error_feedback: ''
		},
		email: {
			is_valid: null,
			error_feedback: ''
		},
		password1: {
			is_valid: null,
			minLength: 5,
			maxLength: 20,
			error_feedback: ''
		},
		password2: {
			is_valid: null,
			error_feedback: ''
		}
	};

	@Ref() readonly user_fullName!: HTMLInputElement;

	public mounted(): any {
		this.user_fullName.focus();
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.USERS.USERS')}, {title: this.$t('MENU.USERS.CREATE_USER')}]);
	}

	private validationMsg(value: any, field: any, feedBack: string): any {
		var name_Pattern = /^[a-zA-Z\u4e00-\u9fa5À-ÿ ]+$/i;
		var email_Pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		switch (feedBack) {
			case 'name':
				if (name_Pattern.test(value) === false) {
					field.error_feedback = this.$t('USERS.ALERT.FORMAT_NAME');
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'email':
				if (email_Pattern.test(value) === false) {
					field.error_feedback = this.$t('USERS.ALERT.FORMAT_EMAIL');
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'password1':
				if (value.length < field.minLength || value.length > field.maxLength) {
					field.error_feedback = this.$t('USERS.ALERT.LIMIT_LENGTH', {min: field.minLength, max: field.maxLength});
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'password2':
				if (value !== this.password1) {
					field.error_feedback = this.$t('USERS.ALERT.CONFIRM_PASSWORD');
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'required':
				if (value == '') {
					field.error_feedback = this.$t('USERS.ALERT.REQUIRED_FIELD');
					field.is_valid = false;
					break;
				}
		}
	}

	private getValidList(): void {
		var obj = this.vFields;
		for (var key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				this.allValid.push(obj[key].is_valid);
			}
		}
	}

	public async submitCreatedUser(event: {preventDefault: () => void}) {
		event.preventDefault();
		this.getValidList();
		const checker = arr => arr.every(Boolean);
		if ((await checker(this.allValid)) && !this.isLoading) {
			this.isLoading = true;
			const createUser: IUserProfileCreate = {
				full_name: this.fullName,
				email: this.email,
				password: this.password2,
				is_superuser: this.isSuperuser
			};

			try {
				await dispatchCreateUser(this.$store, createUser);

				this.makeToast(this.$t('USERS.ALERT.SUCCESS'), 'success');
				this.$router.back();
			} catch {
				this.makeToast(this.$t('USERS.ALERT.FAIL'), 'danger');
			}

			this.isLoading = false;
		}
	}

	private cancel() {
		this.$router.back();
	}
}
