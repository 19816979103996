
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {readHasAdminAccess} from '@/core/services/store/main/getters';
@Component({
	computed: {
		...mapGetters(['layoutConfig'])
	}
})
export default class KTMenu extends Vue {
	[x: string]: any;

	get menuLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.pinterview.horizontal_svg');
	}
	get hasAdminAccess() {
		return readHasAdminAccess(this.$store);
	}

	private hasActiveChildren(match): boolean {
		return this.$route['path'].indexOf(match) !== -1;
	}
}
