
import {Component, Mixins, Prop, Ref, Watch} from 'vue-property-decorator';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {IInterview} from '@/interfaces/exam';

type SearchParams = {
	'user_email': string | undefined;
	'user_name': string | undefined;
	'question_level': string | undefined;
	'labels': string | undefined;
};

@Component({})
export default class viewInterviews extends Mixins(MakeToast) {
	readonly RATING_WIDTH = 91.5;
	public tipData = {
		title: `${this.$t('INTERVIEWS.TABLE.DATA_IN_SEQUENCE')}<br/>
				<span class="text-success">${this.$t('INTERVIEWS.TABLE.CORRECT')}</span>
				/
				<span class="text-warning">${this.$t('INTERVIEWS.TABLE.PARTIAL')}</span>
				/
				<span class="text-danger">${this.$t('INTERVIEWS.TABLE.WRONG')}</span>
				/
				<span class="text-muted">${this.$t('INTERVIEWS.TABLE.NOT_ANSWERED')}</span>`
	};
	@Prop() public interviewList!: Array<IInterview>;
	get infoSVG() {
		return process.env.BASE_URL + 'media/svg/icons/info-question.svg';
	}
}
