
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class MakeToast extends Vue {
	$bvToast: any;
	public makeToast(message: string, variant: string = 'warning', toaster: string = 'b-toaster-top-right', solid: boolean = false) {
		if (variant === 'danger') {
			this.$bvToast.toast(message, {
				title: 'Error',
				variant: variant,
				toaster: toaster,
				solid: solid,
				autoHideDelay: 5000,
				appendToast: false
			});
		} else {
			this.$bvToast.toast(message, {
				title: variant.slice(0, 1).toUpperCase() + variant.slice(1).toLowerCase(),
				variant: variant,
				toaster: toaster,
				solid: solid,
				autoHideDelay: 5000,
				appendToast: false
			});
		}
	}
}
