import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/core/services/store';
import { dispatchCheckLoggedIn } from '@/core/services/store/main/actions';
import { readIsLoggedIn, readHasAdminAccess } from '@/core/services/store/main/getters';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/module/config.module';
import { readInterview } from './core/services/store/interview/getters';
Vue.use(VueRouter);

const routes = [

	{
		path: '/',
		redirect: '/login',
		component: () => import(/* webpackChunkName: "layout" */ '@/view/layout/Layout.vue'),
		children: [
			{
				path: '/admin/dashboard',
				name: 'dashboard',
				component: () => import(/* webpackChunkName: "home" */ '@/view/pages/Dashboard.vue')
			},
			{
				path: '/admin/questions/create',
				name: 'create-question',
				component: () => import(/* webpackChunkName: "create-question" */ '@/view/pages/questions/C&U_Question.vue')
			},
			{
				path: '/admin/questions/:id/edit',
				name: 'edit-question',
				component: () => import(/* webpackChunkName: "create-question" */ '@/view/pages/questions/C&U_Question.vue')
			},
			{
				path: '/admin/questions/view',
				name: 'view-questions',
				component: () => import(/* webpackChunkName: "view-questions" */ '@/view/pages/questions/ViewQuestion.vue')
			},
			{
				path: '/admin/labels',
				name: 'view-labels',
				component: () => import(/* webpackChunkName: "labels-group" */ '@/view/pages/labels/ViewLabels.vue')
			},
			{
				path: '/admin/labels/create',
				name: 'create-label',
				component: () => import(/* webpackChunkName: "labels-group" */ '@/view/pages/labels/C&U_Label.vue')
			},
			{
				path: '/admin/labels/:id/edit',
				name: 'edit-label',
				component: () => import(/* webpackChunkName: "labels-group" */ '@/view/pages/labels/C&U_Label.vue'),
				props: true
			},
			{
				path: '/admin/users',
				name: 'view-users',
				component: () => import(/* webpackChunkName: "users-group" */ '@/view/pages/users/ViewUsers.vue'),
				meta: { requiresAdminAuth: true }
			},
			{
				path: '/admin/users/create',
				name: 'create-user',
				component: () => import(/* webpackChunkName: "users-group" */ '@/view/pages/users/CreateUser.vue'),
				meta: { requiresAdminAuth: true }
			},
			{
				path: '/admin/users/:id/edit',
				name: 'edit-user',
				component: () => import(/* webpackChunkName: "users-group" */ '@/view/pages/users/EditUser.vue'),
				props: true
			},
			{
				path: '/admin/interviews/create',
				name: 'create-interview',
				component: () => import(/* webpackChunkName: "create-interview" */ '@/view/pages/interviews/CreateInterview.vue')
			},
			{
				path: '/admin/interviews/view',
				name: 'view-interviews',
				component: () => import(/* webpackChunkName: "view-interviews" */ '@/view/pages/interviews/ViewInterview.vue')
			},
			{
				path: '/admin/interviews/:code/result',
				name: 'result-interview',
				component: () => import(/* webpackChunkName: "result-interview" */ '@/view/pages/interviews/ResultInterview.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '@/view/pages/Login.vue')
	},
	{
		path: '/interviews',
		name: 'interview',
		redirect: '/interviews/:code/exam',
		component: () => import(/* webpackChunkName: "exam-group" */ '@/view/layout/InterviewLayout.vue'),
		children: [
			{
				path: '/interviews/:code/exam',
				name: 'exam-interview',
				component: () => import(/* webpackChunkName: "exam-group" */ '@/view/pages/interviews/ExamInterview.vue')
			}
		]
	},
	{
		path: '/interviews/end',
		name: 'end-interview',
		component: () => import(/* webpackChunkName: "exam-group" */ '@/view/pages/interviews/EndInterview.vue')
	},
	{
		path: '/interviews/:code',
		name: 'enter-interview',
		component: () => import(/* webpackChunkName: "exam-group" */ '@/view/pages/interviews/EnterInterview.vue')
	},
	{
		path: '*',
		redirect: '/404'
	},
	{
		// the 404 route, when none of the above matches
		path: '/404',
		name: '404',
		component: () => import(/* webpackChunkName: "error-page" */ '@/view/pages/error/Error.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	if ((to.path as string).startsWith('/interviews')) {
		//Make sure Interview always begin from page enter-interview to check code
		if (to.name === 'exam-interview' && !readInterview(store)) {
			next({ name: 'enter-interview', params: { code: to.params.code } });
		} else {
			next();
		}
	} else {
		// Ensure we checked auth before each page load.
		await dispatchCheckLoggedIn(store);
		if (readIsLoggedIn(store)) {
			if (to.path === '/login' || to.path === '/') {
				next({ name: 'dashboard'});
			} else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
				readHasAdminAccess(store) ? next() : next({ name: 'dashboard'});
			} else {
				next();
			}
		} else if (readIsLoggedIn(store) === false) {
			if (to.path === '/' || (to.path as string).startsWith('/admin')) {
				next({ name: 'login'});
			} else {
				next();
			}
		}
	}

	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

export default router;
