
import {api} from '@/api';
import {Component, Watch, Mixins} from 'vue-property-decorator';
import {IInterviewQuestion} from '@/interfaces/exam';
import {readInterview, readInterviewCode} from '@/core/services/store/interview/getters';
import {dispatchSubmitAnswers} from '@/core/services/store/interview/actions';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import MarkdownToHtml from '@/view/content/mixin/MarkdownToHtml.vue';

@Component
export default class ExamInterview extends Mixins(MakeToast, MarkdownToHtml) {
	private interviewcode: string = '';
	private totalQuestions: any = null;
	private tabbedQuestions: any = [];
	private singleQuestion = {} as IInterviewQuestion;
	public prevDisable: boolean = true;
	public nextDisable: boolean = false;
	private tabs: Array<any> = [];
	private currentPage: any = null;
	private isLoading: boolean = false;
	private canSubmit: boolean = true; //disable submit button when true
	private notAnswered: boolean = false;
	private isSubmitting: boolean = false;
	private isGettingQuestion: boolean[] = [];
	private answerTypeText: string = '';
	private singleAnswered: number = 0;
	private multipleAnswered: number[] = [];
	
	private disableCopy = (event) => {
		event.clipboardData.setData("text/plain", "Be a good kid. Don't copy");
		event.preventDefault();
	}

	public async mounted(): Promise<void> {
		this.isLoading = true;
		this.totalQuestions = readInterview(this.$store)!.amount_of_questions;
		this.getPageTabs();
		this.interviewcode = readInterviewCode(this.$store);
		await this.getPagedQuestion(1);
		this.getSingleQuestion(this.tabbedQuestions[0].code);
		document.addEventListener("copy", this.disableCopy , false);
		this.isLoading = false;
	}
	public beforeDestroy(): void {
		document.removeEventListener("copy", this.disableCopy , false);
	}
	//remove markdown-generated html tag in question list on left panel. Only leave <p></p> tag
	public formatTabQuestion(question) {
		const afterMarkdown = this.markdownToHtml(question);
		const tag = '</p>';
		const tagIndex = afterMarkdown.indexOf(tag);
		const newText = afterMarkdown.slice(3, tagIndex);
		return newText;
	}

	checkTabIsActive(code) {
		return this.singleQuestion.code === code;
	}

	public async getPagedQuestion(page: number) {
		await api.getPaginatedInterviewQuestions(this.interviewcode, page).then(response => {
			this.tabbedQuestions = response.data;
			this.currentPage = page;
		});
	}

	// go to the first question when a tabbed page is select/switched to
	public async getFirstQustionOnPage(page: number) {
		await this.getPagedQuestion(page).then(() => this.getFirstQuestion);
	}

	get getFirstQuestion() {
		return this.getSingleQuestion(this.tabbedQuestions[0].code);
	}

	@Watch('singleQuestion', {deep: true})
	onSingleQuestionChanged(question: IInterviewQuestion) {
		const qIdx = this.tabbedQuestions.findIndex(el => el.id === question.id);
		this.prevDisable = false;
		this.nextDisable = false;
		if (qIdx === 0 && this.currentPage ===1) {
			this.prevDisable = true;
		}
		if (qIdx === this.tabbedQuestions.length - 1 && this.currentPage === this.tabs.length) {
			this.nextDisable = true;
		}
	}

	private async questionNavigate(direction) {
		const qIndex = this.tabbedQuestions.findIndex(question => question.id === this.singleQuestion.id);
		switch (direction) {
			case 'previous':
				if (qIndex === 0 && this.currentPage !== 1) {
					await this.getPagedQuestion(this.currentPage - 1).then(res => this.getSingleQuestion(this.tabbedQuestions[9].code));
				} else {
					this.getSingleQuestion(this.tabbedQuestions[qIndex - 1].code);
				}
				break;
			case 'next':
				if (qIndex === this.tabbedQuestions.length - 1 && this.currentPage !== this.tabs.length) {
					await this.getPagedQuestion(this.currentPage + 1).then(res => this.getSingleQuestion(this.tabbedQuestions[0].code));
				} else {
					this.getSingleQuestion(this.tabbedQuestions[qIndex + 1].code);
				}
				break;
		}
	}

	public async getSingleQuestion(questionCode: string) {
		const qIdx = this.tabbedQuestions.findIndex(el => el.code == questionCode);
		this.isGettingQuestion[qIdx] = true;
		await api.getSubmitAnswersToInterview(this.interviewcode, questionCode).then(response => {
			this.singleQuestion = response.data;
			this.isGettingQuestion[qIdx] = false;
			if (response.data.is_multiple_choice) {
				this.answerTypeText = 'Multiple';
				this.multipleAnswered = this.singleQuestion.interviewee_answers;
			} else {
				this.answerTypeText = 'Single';
				this.singleAnswered = this.singleQuestion.interviewee_answers[0];
			}
			this.canSubmit = !this.singleQuestion.interviewee_answers.length;
			this.notAnswered = !this.singleQuestion.interviewee_answers.length;
		});
	}
	@Watch('singleAnswered')
	onSingleAnswerChecked(){
		if(this.singleQuestion.interviewee_answers[0] === this.singleAnswered){
			return;
		}
		this.canSubmit = true;
		this.notAnswered = !this.singleQuestion.interviewee_answers.length;		
	}
	@Watch('multipleAnswered', {deep: true})
	onMultipleAnswerChecked(){
		if(this.checkAnswersEqual(this.singleQuestion.interviewee_answers, this.multipleAnswered)){
			return;
		}
		this.canSubmit = true;
		this.notAnswered = !this.singleQuestion.interviewee_answers.length;
	}

	public async submitAnswers() {
		if (this.isSubmitting) { return; }		
		let answers: any = [];
		if (this.answerTypeText === 'Single') {
			if(this.singleQuestion.interviewee_answers[0] === this.singleAnswered) { 
				return; 
			}
			answers.push(this.singleAnswered);
		} else {
			if(this.checkAnswersEqual(this.singleQuestion.interviewee_answers, this.multipleAnswered)) { 
				return; 
			}
			answers = this.multipleAnswered;
		}
		this.isSubmitting = true;	
		const index = this.tabbedQuestions.map(question => question.code).indexOf(this.singleQuestion.code);
		const data = {question_code: this.singleQuestion.code, answers: answers, is_answered: this.tabbedQuestions[index].is_answered};
		await dispatchSubmitAnswers(this.$store, data).then(res => {
			this.makeToast('Saved successfully!', 'success', 'b-toaster-top-center', true);
			this.isSubmitting = false;
			this.canSubmit = false;
			this.getPagedQuestion(this.currentPage);
		});
		if (this.answerTypeText === 'Single') {
			this.singleQuestion.interviewee_answers[0] = this.singleAnswered
		} else {
			this.singleQuestion.interviewee_answers = this.multipleAnswered
		}
	}

	public checkAnswersEqual(array1:number[], array2:number[]){
		if (array1.length !== array2.length) { return false; }
		if (array1.every(function(element){ return array2.includes(element); })) { 
			return true;
		}
		return false;
	}

	getPageTabs() {
		const pages = Math.ceil(this.totalQuestions / 10);
		let remain = this.totalQuestions % 10;
		if (remain == 0) {
			remain = 10;
		}
		let tabs: Array<any> = [];

		for (let i = 0; i < pages - 1; i++) {
			let pageTab = {text: `${i * 10 + 1} ~ ${(i + 1) * 10}`, pageIdx: Math.ceil(i * 10 + 1 / 10)};
			tabs.push(pageTab);
		}
		let tab = {text: `${(pages - 1) * 10 + 1} ~ ${(pages - 1) * 10 + remain}`, pageIdx: pages};
		tabs.push(tab);
		this.tabs = tabs;
	}
}
