import ApiService from '@/core/services/api.service';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from '@/interfaces';
import {
  IQuestion,
  IgetQuestionQuery,
  ILabel,
  IInterviewProfileCreate,
  IInterviewAnswer,
  IInterviewQuestion,
  IInterviewPagedQuestion,
  IInterview,
  IgetInterviewsQuery
} from '@/interfaces/exam';
import { AxiosPromise } from 'axios';
import { apiUrl } from '@/env';
export const api = {
  //Set Header
  SetHeader: (): void => ApiService.setHeader(),

  //Login
  logInGetToken: (form: URLSearchParams): AxiosPromise => ApiService.post(`${apiUrl}/login/access-token`, form),

  //Questions
  getAllQuestions: (query: IgetQuestionQuery): Promise<{ data: IQuestion[] }> => ApiService.query(`${apiUrl}/question/`, { params: query }),
  createQuestion: (question: IQuestion[]): AxiosPromise => ApiService.post(`${apiUrl}/question/`, question),
  getQuestion: (questionId: number): Promise<{ data: IQuestion }> => ApiService.get(`${apiUrl}/question/${questionId}`),
  updateQuestion: (questionId: number, question: IQuestion): Promise<{ data: IQuestion }> => ApiService.put(`${apiUrl}/question/${questionId}`, question),
  deleteQuestion: (questionId: number): AxiosPromise => ApiService.delete(`${apiUrl}/question/${questionId}`),
  uploadQuestions: (formData: FormData): AxiosPromise => ApiService.post(`${apiUrl}/question/upload`, formData, {'Content-Type': 'multipart/form-data'}),

  //Interviews
  getInterviews: (queryString: string): Promise<{ data: IInterview[] }> => ApiService.get(`${apiUrl}/interview/?${queryString}`),
  getInterviewsWithQuery: (query: IgetInterviewsQuery): Promise<{ data: IInterview[] }> => ApiService.query(`${apiUrl}/interview/`, { params: query }),
  createInterview: (interview: IInterviewProfileCreate): AxiosPromise => ApiService.post(`${apiUrl}/interview/`, interview),
  getInterviewForAdmin: (interview_code: string): Promise<{ data: any }> => ApiService.get(`${apiUrl}/interview/${interview_code}/results`),
  getInterview: (interview_code: string): Promise<{ data: IInterview }> => ApiService.get(`${apiUrl}/interview/${interview_code}`),
  activeInterview: (interview_code: string, obj: { email: string }): AxiosPromise => ApiService.put(`${apiUrl}/interview/${interview_code}`, obj),
  closeInterview: (interview_code: string): Promise<{ data: IInterview }> => ApiService.put(`${apiUrl}/interview/${interview_code}/close`, {}),
  getPaginatedInterviewQuestions: (interview_code: string, page_num: number): Promise<{ data: Array<IInterviewPagedQuestion> }> =>
    ApiService.query(`${apiUrl}/interview/${interview_code}/question`, { params: { page_num: page_num } }),
  getSubmitAnswersToInterview: (interview_code: string, question_code: string): Promise<{ data: IInterviewQuestion }> =>
    ApiService.get(`${apiUrl}/interview/${interview_code}/question/${question_code}`),
  putSubmitAnswersToInterview: (interview_code: string, question_code: string, data: IInterviewAnswer): AxiosPromise =>
    ApiService.put(`${apiUrl}/interview/${interview_code}/question/${question_code}`, data),
  deleteInterview: (interview_code: string): AxiosPromise => ApiService.delete(`${apiUrl}/interview/${interview_code}`),

  //Labels
  getLabels: (): Promise<{ data: ILabel[] }> => ApiService.get(`${apiUrl}/label/`),
  createLabel: (label: ILabel): AxiosPromise => ApiService.post(`${apiUrl}/label/`, label),
  putLabel: (labelId: number, label: ILabel): AxiosPromise => ApiService.put(`${apiUrl}/label/${labelId}`, label),
  deleteLabel: (labelId: number): AxiosPromise => ApiService.delete(`${apiUrl}/label/${labelId}`),

  //Users
  getUsers: (): Promise<{ data: IUserProfile[] }> => ApiService.get(`${apiUrl}/user/`),
  createUser: (userProfile: IUserProfileCreate): AxiosPromise => ApiService.post(`${apiUrl}/user/`, userProfile),
  getMe: (): Promise<{ data: IUserProfile }> => ApiService.get(`${apiUrl}/user/me`),
  updateMe: (userProfile: IUserProfileUpdate): AxiosPromise => ApiService.put(`${apiUrl}/user/me`, userProfile),
  createUserOpen: (userProfile: IUserProfileCreate): AxiosPromise => ApiService.post(`${apiUrl}/user/open`, userProfile),
  updateUser: (userId: number, userProfile: IUserProfileUpdate): AxiosPromise => ApiService.put(`${apiUrl}/user/${userId}`, userProfile),
  deleteUser: (userId: number): AxiosPromise => ApiService.delete(`${apiUrl}/user/${userId}`)
};
