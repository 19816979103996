
import {Component, Emit, Prop, Mixins} from 'vue-property-decorator';
import {ILabel, IQuestion} from '@/interfaces/exam';
import MarkdownToHtml from './mixin/MarkdownToHtml.vue';

interface Label extends ILabel {
	isActive?: boolean;
}

@Component
export default class ListQuestion extends Mixins(MarkdownToHtml) {
	public mainLabels: Array<Label> = [];

	@Prop({default: true}) public isAuthedUser!: boolean;
	@Prop({default: true}) public canEditDelete!: boolean;
	@Prop() public index!: number;
	@Prop() public question!: IQuestion;
	@Prop({default: true}) public seeAnswerOptions!: boolean;
	@Prop({default: true}) public seeQuestionlabels!: boolean;

	@Emit()
	public open(question: IQuestion): IQuestion {
		return question;
	}

	@Emit()
	public remove(questionId: number): number {
		return questionId;
	}
}
