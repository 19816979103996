import { BIconChevronDoubleLeft } from 'bootstrap-vue';
import { openDB } from 'idb';
const INDEXEDDB_VERSION = 3

// init or upgrade
export const getIdb = openDB('examData-db', INDEXEDDB_VERSION, {
	upgrade(db) {
		console.log(`indexedDB upgrading to version ${INDEXEDDB_VERSION}`)
		if (!db.objectStoreNames.contains('labels')) {
			db.createObjectStore('labels', { keyPath: 'id' });
		}
		if (!db.objectStoreNames.contains('questions')) {
			db.createObjectStore('questions', { keyPath: 'id' });
		}
		if (!db.objectStoreNames.contains('interviews')) {
			db.createObjectStore('interviews', { keyPath: 'id' });
		}
	},
	blocked() {
		console.warn('indexedDB blocked by old version')
	},
	blocking() {
		console.warn(`This indexedDB version ${INDEXEDDB_VERSION} is blocking future indexedDB update`)
	},
	terminated() {
		console.warn('indexedDB closed unexpectedly')
	}

});


// readonly transaction (completion not required to end transaction)

export const checkResourceNotEmpty = function (db, tableName: string): Promise<number> {
	const tx = db.transaction(tableName, 'readonly');
	return tx.store.count();
};
export const passResourceToVue = function (db, tableName: string) {
	const transaction = db.transaction(tableName, 'readwrite');
	return transaction.store.getAll();
};

// readwrite transaction

export const createResource = async function (db, tableName: string, resource: Array<any>) {
	const transaction = db.transaction(tableName, 'readwrite');
	const promiseArray = Array.from({ length: resource.length }, (_, i) => {
		transaction.store.add(resource[i]);
	});
	await Promise.all(promiseArray);
	return await transaction.complete;
};
export const createOrUpdateResource = async function (db, tableName: string, resource: Array<any>) {
	const transaction = db.transaction(tableName, 'readwrite');
	for (let i = 0; i < resource.length; i++) {
		const item = resource[i];
		const itemExists = await transaction.store.get(resource[i].id);
		if (!itemExists) await transaction.store.add(item);
		else await transaction.store.put(item);
	}
	return transaction.complete;
};
export const clearResource = function (db, tableName: string) {
	const transaction = db.transaction(tableName, 'readwrite');
	transaction.store.clear();
	return transaction.complete;
};
