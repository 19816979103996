
import {getIdb, clearResource} from '@/core/services/indexedDB.service';
import {Component, Mixins} from 'vue-property-decorator';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import Loader from '@/view/content/Loader.vue';
import {mapGetters} from 'vuex';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
import MarkdownToHtml from '@/view/content/mixin/MarkdownToHtml.vue';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

import KTUtil from '@/assets/js/components/util';

import {dispatchActivate, dispatchCheckActivate} from '@/core/services/store/interview/actions';
import {api} from '@/api';
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from '@/core/services/store/module/htmlclass.module';
import HtmlClass from '@/core/services/htmlclass.service';

@Component({
	components: {
		Loader
	},
	computed: {
		...mapGetters(['layoutConfig'])
	}
})
export default class Interview extends Mixins(MakeToast, MarkdownToHtml) {
	[x: string]: any;
	private state: string = 'signin';
	private code: string = this.$route.params.code;
	private form: any = {
		email: ''
	};
	private errorMessage: string = '';
	private rules: string[] = [
		'The timer starts once you enter the page.',
		'Questions are not weighted.',
		'The single choice question gives one point if correct, 0 if incorrect. The multiple choice question gives one point if correct, 0 point if any choice is incorrect.',
		'Please provide the email address to start the test.'
	];
	private question_description: string =
		'**Assuming that x is >= 0, which of the statements below is false after the code is executed?**\n```\ny = 1\nDo While (y <= x)\n        y = y * 2\nLoop\n```\n';
	private answersList: string[] = ['y may be equal to x.', 'y must be a power of 2.', 'y may be odd.', 'y may be equal to x + 1.'];
	private answered: string = '';
	private isSubmit: boolean = false;
	private isSuccess: boolean = false;
	private isChecked: boolean = false;
	private max_time: number = 0;
	private amount_Of_questions: number = 0;

	get seidorLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.seidor.dark-background');
	}

	get loaderEnabled() {
		return !/false/.test(this.layoutConfig('loder.type'));
	}
	get loaderLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('loader.logo');
	}
	get loginError() {
		//Interview readLoginError
		return false;
	}

	public beforeMount(): void {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	}

	private async mounted() {
		// clear any sensitive resource that can be possibly stored in cache (indexedDB)
		await getIdb.then(async db => {
			await clearResource(db, 'questions');
			await clearResource(db, 'labels');
		});
		//check if interview code is correct and interview activated status
		await dispatchCheckActivate(this.$store, this.code);
		this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');

		const signin_form = KTUtil.getById('kt_login_signin_form');
		const fv = formValidation(signin_form, {
			fields: {
				email: {
					validators: {
						notEmpty: {
							message: 'Email is required'
						},
						emailAddress: {
							message: 'The value is not a valid email address'
						}
					}
				}
			},
			plugins: {
				trigger: new Trigger(),
				submitButton: new SubmitButton(),
				bootstrap: new Bootstrap()
			}
		});
		await api.getInterview(this.code).then(res => {
			(this.max_time = res.data.max_time), (this.amount_Of_questions = res.data.amount_of_questions);
		});
	}

	public async login(): Promise<void> {
		const submitButton = <HTMLElement>this.$refs['kt_login_signin_submit'];
		const arrowIcon = <HTMLElement>this.$refs['arrow_icon'];
		submitButton.classList.add('spinner', 'spinner-dark', 'spinner-right');
		arrowIcon.classList.add('opacity-0')
		await api
			.activeInterview(this.code, this.form)
			.then(response => {
				dispatchActivate(this.$store, response.data.remaining_time);
			})
			.catch(error => {
				this.errorMessage = error.response.data.detail;
			});

		submitButton.classList.remove('spinner', 'spinner-dark', 'spinner-right');
		arrowIcon.classList.remove('opacity-0')
	}

	private submitAnswers(): void {
		this.isSubmit = true;
		setTimeout(() => {
			(this.isSubmit = false), (this.isSuccess = true);
		}, 700);
	}
	private hideSuccessText(): void {
		this.isSuccess = false;
	}
}
