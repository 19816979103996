import Vue from "vue";
import Vuex, { StoreOptions } from 'vuex';

import htmlClassModule from "./module/htmlclass.module";
import configModule from "./module/config.module";
import breadcrumbsModule from "./module/breadcrumbs.module";
import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { interviewModule } from './interview' 

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    htmlClass: htmlClassModule,
    config: configModule,
    breadcrumbs: breadcrumbsModule,
    main: mainModule,
    admin: adminModule,
    interview: interviewModule
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
