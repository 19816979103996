
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
	computed: {
		...mapGetters(['layoutConfig'])
	}
})
export default class KTFooter extends Vue {
	[x: string]: any;

	/**
	 * Check if footer container is fluid
	 */
	get widthFluid(): boolean {
		return this.layoutConfig('footer.width') === 'fluid';
	}
}
