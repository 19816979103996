import { IInterview } from "@/interfaces/exam";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { InterviewState } from "./state";

export const mutations = {
    setInterviewCode(state: InterviewState, payload: string): void {
        state.interviewCode = payload;
    },
    setInterview(state: InterviewState, payload: IInterview): void {
        state.interview = payload;
    },
    setActivate(state: InterviewState, payload: boolean): void {
        if (state.interview) {
            state.interview.is_activated = payload;
        }
    },
    setLastSubmitTime(state: InterviewState, payload: string): void {
        state.lastSubmitTime = payload;
    },
    setInterviewRemainingTime(state: InterviewState, payload: string): void {
        if (state.interview) {
            state.interview.remaining_time = payload;
        }
    },
    increaseInterviewAmountOfAnsweredQuestions(state: InterviewState): void {
        if (state.interview) {
            state.interview.amount_of_answered_questions++;
        }
    }
}

const { commit } = getStoreAccessors<InterviewState, State>('');

export const commitSetInterviewCode = commit(mutations.setInterviewCode);
export const commitSetInterview = commit(mutations.setInterview);
export const commitSetActivate = commit(mutations.setActivate);
export const commitSetLastSubmitTime = commit(mutations.setLastSubmitTime);
export const commitSetInterviewRemainingTime = commit(mutations.setInterviewRemainingTime);
export const commitIncreaseInterviewAmountOfAnsweredQuestions = commit(mutations.increaseInterviewAmountOfAnsweredQuestions);
