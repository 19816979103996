
import {Watch, Component, Mixins, Ref} from 'vue-property-decorator';
import {api} from '@/api';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import ResultQuestion from '@/view/content/ResultQuestion.vue';
import Pagination from '@/view/content/Pagination.vue';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import VueApexCharts from 'vue-apexcharts';

@Component({components: {ResultQuestion, Pagination, VueApexCharts}})
export default class interviewResult extends Mixins(MakeToast) {
	// pagination variables
	public currentPage = 1;
	public recordPerPage = 5;

	public rawInterview = {} as any;
	private code: string = this.$route.params.code;
	public isLoading = false;
	public questionAmount = 0;
	public filter = 'CORRECT' as string | null;
	public filterArray = ['CORRECT', 'PARTIALLY', 'WRONG', null];
	public series = [
		{
			name: '',
			data: [] as Array<string | number>
		}
	];
	public chartOptions = {
		chart: {
			height: '380',
			width: '380',
			align: 'center',
			type: 'radar',
			toolbar: {
				show: false
			}
		},
		title: {
			text: this.$t('INTERVIEWS.RESULT.MAX_SCORE'),
			align: 'center',
			margin: 50
		},
		xaxis: {
			categories: [],
			labels: {
				maxWidth: 100,
				style: {
					colors: ['fd7e14', '#007bff', '#28a745', '#6610F2', '#3F4254'],
					fontSize: '12px',
					fontWeight: 700
				}
			}
		},
		yaxis: {
			tickAmount: 4,
			min: 0,
			max: 100
		},
		dataLabels: {
			enabled: true,
			background: {
				enabled: true,
				borderRadius: 2
			}
		},
		plotOptions: {
			radar: {
				polygons: {
					strokeColors: ['#999999', '#999999', '#999999', '#999999', '#999999'],
					connectorColors: ['#999999', '#999999', '#999999', '#999999', '#999999']
				}
			}
		},
		responsive: [
			{
				breakpoint: 577,
				options: {
					chart: {
						height: 250,
						width: 250
					},
					xaxis: {
						labels: {
							style: {
								fontSize: '8px'
							}
						}
					}
				}
			}
		]
	};

	@Ref('spinner') spinner!: HTMLElement;
	public async created() {
		this.isLoading = true;
		await this.getInterviews();
		const date = new Date(this.interviewData.start_time);
		// map lang in i18n to locale
		const lang = localStorage.getItem('language') || 'en';
		let localelang;
		if (lang === 'ch') {
			localelang = 'zh-TW';
		}
		if (lang === 'es') {
			localelang = 'es-es';
		}
		const dateFormatOptions = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};
		//@ts-expect-error
		this.interviewData.start_time = date.toLocaleString(localelang, dateFormatOptions).toUpperCase();
		// Get data for polygon chart
		if (this.interviewData.result) this.chartData();
		// Remove questions that aren't answered by interviewee
		if (this.interviewData.questions.length) {
			this.questionAmount = this.interviewData.questions.length;
		}
		for (let i = 0; i <= this.filterArray.length - 1; i++) {
			const filteredResult = this.interviewData.questions.find(question => question.answer_status === this.filter);
			if (filteredResult) break;
			this.filter = this.filterArray[i + 1];
		}
		this.isLoading = false;
	}
	public async mounted(): Promise<void> {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.INTERVIEWS.INTERVIEWS')}, {title: this.$t('MENU.INTERVIEWS.INTERVIEW_RESULT')}]);
	}
	beforeUpdate() {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.remove('spinner');
	}
	public async getInterviews(): Promise<void> {
		await api
			.getInterviewForAdmin(this.code)
			.then(response => {
				this.rawInterview = response.data;
			})
			.catch((error: any) => {
				this.makeToast(this.$t('QUESTIONS.ALERT.API_ERROR_MESSAGE'), 'danger');
			});
	}

	//watch this.rawinterview deep to prevent reactivity issue
	get interviewData() {
		return this.rawInterview;
	}
	get interviewTime() {
		// some old data may not have attribute "interview_time"
		if (this.interviewData.interview_time) {
			const interviewTimeArray = this.interviewData.interview_time.split(':');
			return `${Number(interviewTimeArray[0] * 60) + Number(interviewTimeArray[1])} ${this.$t('INTERVIEWS.RESULT.MINUTES')}`;
		}
		return this.$t('INTERVIEWS.RESULT.TIME_NOT_AVAILABLE');
	}
	get questions() {
		this.currentPage = 1;
		return this.interviewData.questions.filter(question => question.answer_status === this.filter);
	}
	get questionsWithPage() {
		let pageStart = (this.currentPage - 1) * 5;
		return this.questions.slice(pageStart, pageStart + 5);
	}
	public onPageChanged(val: number) {
		this.currentPage = val;
	}
	public getScoreClass(score: number) {
		if (score > 85) return 'text-success';
		else if (score > 60) return 'text-warning';
		else if (score > 30) return 'text-danger';
		else return 'text-primary';
	}
	public chartData(): void {
		let labelDatas = this.interviewData.result.details;
		let labelNames = labelDatas.map(labelData => {
			if (labelData.label_name.includes('_')) {
				return labelData.label_name.split('_');
			}
			return labelData.label_name;
		});
		this.series[0].name = this.$t('DASHBOARD.RATIO');
		this.series[0].data = this.interviewData.result.details.map(labelData => Math.round(labelData.fully_correct_ratio * 100));
		// force apexchart to update with deep clone
		this.series = JSON.parse(JSON.stringify(this.series));
		this.chartOptions.xaxis.categories = labelNames;
		// force apexchart to update with deep clone
		this.chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
	}
	get profilePicture(): string {
		return process.env.BASE_URL + 'media/svg/avatars/002-boy.svg';
	}
	get timePerQuestion(): string {
		let seconds = Math.round((this.interviewData.max_time * 60) / this.interviewData.amount_of_questions);
		return `${Math.floor(seconds / 60)} ${this.$t('INTERVIEWS.FORM.MINS')} ${Math.floor(seconds % 60)} ${this.$t('INTERVIEWS.FORM.SECS')}`;
	}
	get calcScore(): number {
		return Math.round(100 * (this.interviewData.result.total_correct_questions / this.interviewData.amount_of_questions));
	}

	private back() {
		this.$router.back();
	}
}
