import { api } from '@/api';
import router from '@/router';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from "../state";
import { readInterviewCode, readInterviewLastSubmitTime } from './getters';
import {
    commitSetInterviewCode,
    commitSetInterview,
    commitSetActivate,
    commitSetLastSubmitTime,
    commitSetInterviewRemainingTime,
    commitIncreaseInterviewAmountOfAnsweredQuestions
} from './mutations';
import { InterviewState } from "./state";

type InterviewContext = ActionContext<InterviewState, State>;

export const actions = {
    async actionCheckActivate(context: InterviewContext, code: string): Promise<void> {
        try {
            const response = await api.getInterview(code);
            if (response) {
                commitSetInterviewCode(context, code);
                commitSetInterview(context, response.data);
                if (response.data.is_closed) {
                    dispatchRouteExamEnd(context);
                }else if (response.data.is_activated) {
                    commitSetLastSubmitTime(context, response.data.remaining_time);
                    dispatchRouteExam(context);
                }
            }
        } catch {
            dispatchRoute404(context);
        }
    },
    actionActivate(context: InterviewContext, max_time: string): void {
        commitSetActivate(context, true);
        commitSetInterviewRemainingTime(context, max_time);
        commitSetLastSubmitTime(context, max_time);
        dispatchRouteExam(context);
    },
    actionSetInterviewTime(context: InterviewContext, remaining_time: string): void {
        commitSetInterviewRemainingTime(context, remaining_time);

    },
    async actionSubmitAnswers(context: InterviewContext, data: { question_code: string, answers: number[], is_answered: boolean }): Promise<void> {
        const interviewCode = readInterviewCode(context);
        const response = await api.getInterview(interviewCode);
        if (response) {
            commitSetInterview(context, response.data);
            const hmsToSecond = (time: string): number => {
                const arr = time.split(":").map(str => Number(str));
                const totalSeconds = arr[0] * 60 * 60 + arr[1] * 60 + arr[2];
                return totalSeconds;
            }
            const spendingTime: number = hmsToSecond(readInterviewLastSubmitTime(context)) - hmsToSecond(response.data.remaining_time);
            await api.putSubmitAnswersToInterview(interviewCode, data.question_code, {
                answers: data.answers,
                time_spent: spendingTime
            })
            commitSetLastSubmitTime(context, response.data.remaining_time);
            if (!data.is_answered && data.answers.length !== 0) {
                commitIncreaseInterviewAmountOfAnsweredQuestions(context);
            }
        }
    },
    actionRouteExam(context: InterviewContext): void {
        if (router.currentRoute.path !== '/interviews/:code/exam') {
            router.push(`/interviews/${readInterviewCode(context)}/exam`);
        }
    },
    actionRouteExamEnd(): void {
        if (router.currentRoute.path !== '/interviews/end') {
            router.push('/interviews/end');
        }
    },
    actionRoute404(): void {
        if (router.currentRoute.path !== '/404') {
            router.push('/404');
        }
    },
};

const { dispatch } = getStoreAccessors<InterviewState | any, State>('');

export const dispatchCheckActivate = dispatch(actions.actionCheckActivate);
export const dispatchActivate = dispatch(actions.actionActivate);
export const dispatchSetInterviewTime = dispatch(actions.actionSetInterviewTime);
export const dispatchSubmitAnswers = dispatch(actions.actionSubmitAnswers);
export const dispatchRouteExam = dispatch(actions.actionRouteExam);
export const dispatchRouteExamEnd = dispatch(actions.actionRouteExamEnd);
export const dispatchRoute404 = dispatch(actions.actionRoute404);
