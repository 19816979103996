/* eslint-disable no-console */
import { register } from 'register-service-worker';

const CURRENT_VERSION = '0.1.6'
const version = localStorage.getItem('pinterview-version');
const STORAGE_FULL_ERR = ['QUOTA_EXCEEDED_ERR', 'NS_ERROR_DOM_QUOTA_REACHED', 'QuotaExceededError', 'W3CException_DOM_QUOTA_EXCEEDED_ERR']

if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
		},
		registered() {
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('Refreshing...New content is downloading.');
			if ( version === (null || CURRENT_VERSION) ) {
				return
			}else{
				try{
					localStorage.setItem('pinterview-version', CURRENT_VERSION);
					if(localStorage.getItem('pinterview-version') === CURRENT_VERSION ){
						setTimeout(() => { window.location.reload() }, 1000)						
					}
				} catch ( error:any ) {
					if ( STORAGE_FULL_ERR.includes(error.message) ) {
						console.warn('Storage quota reached. Content may not be saved.')
					  } else {
						  console.warn('LocalStorage not available')
					  }
				}				
			}
		},
		updated() {
			console.log('New content is available.');
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error:any) {
			console.error('Error during service worker registration:', error);
		}
	});
}
