// Mandarin
export const locale = {
	TRANSLATOR: {
		SELECT: '選擇語言'
	},
	SUBJECT: {
		LABEL: '標籤',
		QUESTION: '題目',
		INTERVIEW: '面試',
		USER: '使用者'
	},
	MENU: {
		DASHBOARD: '主控台',
		NEW: '新的',
		ACTIONS: '行動',
		CREATE_POST: '新發文',
		PAGES: '頁面模板',
		FEATURES: '網站特色',
		APPS: 'APP',
		QUESTIONS: {
			QUESTIONS: '管理題目',
			CREATE_QUESTION: '新增題目',
			VIEW_QUESTIONS: '查看題目',
			EDIT_QUESTIONS: '編輯題目'
		},
		LABELS: {
			LABELS: '管理標籤',
			CREATE_LABEL: '新增標籤',
			EDIT_LABEL: '編輯標籤'
		},
		USERS: {
			USERS: '管理使用者',
			CREATE_USER: '新增使用者',
			EDIT_USER: '編輯使用者'
		},
		INTERVIEWS: {
			INTERVIEWS: '面試',
			CREATE_INTERVIEW: '新增面試',
			VIEW_INTERVIEWS: '查看面試',
			INTERVIEW_RESULT: '面試結果'
		}
	},
	QUESTIONS: {
		CREATE_QUESTIONS: {
			LABEL: {
				QUESTION: '描述',
				ANSWERS: '答案',
				LABEL: '標籤'
			},
			PLACEHOLDER: {
				LABEL_PLACEHOLDER: '新增此標籤',
				MULTISELECT_PLACEHOLDER: '搜尋或新增標籤',
				SINGLESELECT_PLACEHOLDER: '選擇技術水平'
			},
			BUTTON: {
				ADD_ANSWER: '新增答案',
				CREATE_QUESTION: '確認新增問題',
				BACK: '上一頁',
				UPDATE_QUESTION: '更新問題'
			},
			TOOLTIP: {
				LABELS: '- 問題類別 <br/>- 請至少選擇一個標籤'
			}
		},
		VIEW_QUESTIONS: {
			VIEW_ANSWERS: '顯示選項',
			EDIT_ANSWERS: '編輯選項',
			VIEW_LABELS: '顯示標籤',
			GET_PAGE_LINK: '取得連結',
			RESET: '重新整理',
			ADDLABEL_MESSAGE: '增加標籤來取得題目吧！',
			LOADED_MESSAGE: '問題成功載入',
			PLACEHOLDER: {
				MULTISELECT_PLACEHOLDER: '用標籤搜尋問題'
			}
		},
		ALERT: {
			VALIDATION_ERROR: '驗證問題',
			ANSWER_EMPTY_MESSAGE: '問題題目是必填項目',
			ANSWER_NOEMPTY_MESSAGE: '選項不可為空',
			ANSWERS_MINIMUM_MESSAGE: '選項的最少數量為兩個',
			ANSWERS_MAXIMUM_MESSAGE: '選項的最多數量為六個',
			ANSWERS_NOVALID_MESSAGE: '請選擇至少一個選項為正確答案',
			ANSWERS_DUPLICATE_MESSAGE: '選項有重複',
			ANSWERS_UPDATE_SUCCESS: '選項編輯成功',
			ANSWERS_CANT_MODIFY: '在編輯問題模式中你只能該更改選項的描述',
			NOT_SAVED_MESSAGE: '注意，您所作的變更並未儲存',
			API_ERROR_MESSAGE: '無法取得資源',
			NO_QUESTION: '目前沒有問題可以顯示',
			LABEL_NOSELECT_MESSGE: '請選擇至少一個標籤',
			LABEL_NOVALID_MESSGE: '標籤不得含有任何數字與空格',
			LABEL_MAXIMUM_MESSGE: '標籤長度不得超過214個字母',
			LABEL_GET_ERROR_MESSAGE: '無法從伺服器取得標籤資源，可能是網路或伺服器所造成的錯誤，請稍後再試',
			QUESTION_EMPTY_MESSAGE: '題目內容不得為空',
			QUESTION_CREATE_MESSAGE: '題目新增成功',
			QUESTION_CREATE_ERROR_MESSAGE: '無法新增問題，可能是網路或伺服器所造成的錯誤，請稍後再試',
			QUESTION_UPDATE_MESSAGE: '題目更新成功',
			QUESTION_UPDATE_ERROR_MESSAGE: '無法更新問題，可能是網路或伺服器所造成的錯誤，請稍後再試',
			QUESTION_UPLOAD_SUCCESS: ' 檔案{file1}成功儲存為{file2} '
		},
		BUTTON: {
			NEW_QUESTION: '新增問題',
			ADD_ANSWER: '新增選項',
			UPDATE_QUESTION: '更新問題',
			UPLOAD_QUESTIONS_BULK: '批量上傳問題',
			UPDATE_ANSWERS: '更新選項',
			RESET: '還原更改'
		}
	},
	LABELS: {
		PAGE_TITLE: '管理標籤',
		BUTTON_NEW_LABEL: '新增標籤',
		NO_LABEL_TEXT: '目前無任何標籤',
		TOOLTIP: {
			EDIT: '編輯',
			DELETE: '刪除'
		},
		POPOVER: {
			TEXT: '您確定要刪除此標籤？',
			CANCEL: '取消',
			OK: '確定'
		},
		FORM: {
			CREATE_PAGE_TITLE: '新增標籤',
			EDIT_PAGE_TITLE: '編輯標籤',
			LABEL_TITLE: '名稱',
			LABEL_DESCRIPTION: '描述',
			LABEL_COLOR: '背景顏色',
			LABEL_REMINDER: '請使用大寫,如果需要分隔文字，請使用空格或底線',
			BUTTON_CREATE: '新增',
			BUTTON_CANCEL: '取消',
			BUTTON_SAVE: '儲存更改'
		},
		TABLE: {
			NAME: '名稱',
			DESCRIPTION: '描述',
			ACTION: '操作'
		},
		ALERT: {
			BAN_CHARACTER: '標籤名稱含有不符合格式的特殊字元',
			LIMIT_LENGTH: '標籤必須有 2-20 個字母',
			BAN_DUPLICATED: '此標籤已經存在',
			SERVER_ERROR: '網路或伺服器錯誤，請稍後在試',
			GOOD: '很好！',
			SUCCESS: '儲存成功!',
			DELETED: '刪除成功!'
		},
		COLORPALETTE: {
			GREEN_CYAN: '綠青色',
			DARK_SEA_GREEN: '深海綠',
			GREEN_SCREEN: '綠幕',
			BLUE_GRAY: '藍灰色',
			BLUE: '藍色',
			DARL_VIOLET: '深紫色',
			ROSE_RED: '玫瑰紅色',
			MAGENTA_PINK: '品粉紅',
			TITANIUM_YELLOW: '鈦黃',
			CARROT_ORANGE: '胡蘿蔔橙'
		}
	},
	USERS: {
		PAGE_TITLE: {
			CREATE_USER: '新增使用者',
			MANAGE_USERS: '管理使用者',
			EDIT_USER: '編輯使用者'
		},
		TABLE: {
			NAME: '姓名',
			EMAIL: '信箱',
			IS_ACTIVE: '啟用',
			IS_SUPERUSER: '管理者',
			ACTION: '操作'
		},
		BUTTON_NEW_USER: '新用戶',
		FORM: {
			EMAIL: '信箱',
			NAME: '姓名',
			PASSWORD: '密碼',
			NEW_PASSWORD: '新密碼',
			CONFIRM_PASSWORD: '確認密碼',
			ROLE: '角色',
			CONSULTANT: '顧問',
			ADMIN: '管理者',
			CONSULTANT_TEXT: '公司的顧問',
			ADMIN_TEXT: '公司的管理者',
			IS_ACTIVE: '此用戶已啟用',
			NOT_ACTIVE: '此用戶未啟用',
			ACTIVE: '啟用',
			BUTTON_CANCEL: '取消',
			BUTTON_SAVE: '儲存',
			CHANGE_PASSWORD: '重設密碼',
			YOU: '你'
		},
		ALERT: {
			REQUIRED_FIELD: '此欄位為必填',
			FORMAT_NAME: '輸入格式不正確',
			LIMIT_LENGTH: '此欄位長度必須 {min} - {max} 個字符',
			FORMAT_EMAIL: '此信箱無效',
			CONFIRM_PASSWORD: '此密碼不符合',
			SERVER_ERROR: '網路或伺服器錯誤，請稍後再試',
			GREAT: '極好！',
			SUCCESS: '儲存成功！',
			DELETED: '刪除成功！',
			ERROR: '您沒有足夠的權限',
			FAIL: '信箱已存在系統資料中'
		},
		TOOLTIP: {
			EDIT: '編輯',
			DELETE: '刪除',
			EMAIL: '- 每個郵箱只能註冊一次',
			PASSWORD: '- 使用 5 到 20 個字符 <br/>- 數字和特殊字符可用',
			CONFIRM_PASSWORD: '請再次輸入密碼',
			ACTIVE: '- 默認是已啟用 <br/>- 可在“編輯用戶”頁面上進行編輯',
			INACTIVE: '不允許未啟用用戶登錄'
		}
	},
	INTERVIEWS: {
		FORM: {
			TITLE: '新增面試',
			NAME: '姓名',
			EMAIL: '信箱',
			AMOUNT_OF_QUESTIONS: '題目',
			QUESTION_UNIT: '題',
			TIME_OF_TEST: '時間',
			TEST_TIME_UNIT: '分鐘',
			OUTCOME_OF_EXAM: '測試分數',
			VERY_HARD: '<span class="font-weight-bolder text-danger">非常困難</span>',
			HARD: '<span class="font-weight-bolder text-danger">困難</span>',
			MEDIUM: '<span class="font-weight-bolder text-success">中階</span>',
			EASY: '<span class="font-weight-bolder text-success">基礎</span>',
			JUNIOR: '初階人員',
			SENIOR: '資深人員',
			LABELS: '標籤',
			MULTISELECT_PLACEHOLDER: '輸入關鍵字查詢或選擇標籤',
			LINK: '網址',
			COPY: '複製',
			BACK: '上一頁',
			ENTER_DETAILS: '輸入資料',
			REVIEW_AND_SUBMIT: '確認並新增',
			REVIEW_DETAILS_AND_SUBMIT: '確認資料後新增面試',
			NEXT_STEP: '下一步',
			PREVIOUS: '上一步',
			CONTINUE_CREATE: '繼續新增',
			MINS: '分',
			SECS: '秒'
		},
		ALERT: {
			REQUIRED_FIELD: '此表格為必填項目',
			FORMAT_NAME: '格式有誤',
			FORMAT_EMAIL: '電子信箱格式有誤',
			FORMAT_NUMBER: '此欄位只能填入數字',
			SERVER_ERROR: '網路或伺服器錯誤，請稍後在試',
			LABEL_NOSELECT_MESSGE: '請選擇至少一個標籤',
			CREATE_SUCCESS: '面試新增成功',
			CREATE_FAIL: '無法新增面試，可能是網路或伺服器所造成的錯誤，請稍後再試',
			DEFAULT_VALUE_CHANGED: '請注意，您已經修改了預設的數字'
		},
		SEARCH_BANNER: {
			TITLE: '搜尋面試',
			SUBTITLE: '觀測面試者的評價與表現',
			NEW_INTERVIEW: '新增面試'
		},
		TABLE: {
			THEAD: ['狀態', '面試者', '作答狀況', '評價', '面試編碼', '標籤', '操作', '考試日期'],
			STATUS: {NOT_STARTED: '尚未開始', FINISHED: '已完成', TESTING: '正在進行'},
			DISPLAY_RECORDS: '目前顯示 {total} 個項目中的 {number} 個項目',
			LOADING: '正在載入...',
			DATA_IN_SEQUENCE: '資料依序排列如下：',
			CORRECT: '正確 ',
			PARTIAL: '部份正確',
			WRONG: '錯誤',
			NOT_ANSWERED: '未回答',
			NO_INTERVIEW: '目前沒有測驗可以顯示',
			NOT_DONE: '測驗未完成，沒有資料可以顯示',
			ONGOING: '測驗進行中，無法刪除'
		},
		RESULT: {
			MULTIPLE_IP: '偵測到多重IP',
			EXAM_DATE: '考試日期',
			CREATOR: '考試創建者',
			NOT_AVAILABLE: '沒有時間可以顯示',
			GIVEN_TIME: '考試時長',
			TAKEN_TIME: '花費時間',
			TIME_NOT_AVAILABLE: '沒有時間可以顯示',
			GIVEN_QUESTIONS: '問題數量',
			MINUTES: '分鐘',
			QUESTIONS: '問題',
			TOTAL_QUESTION: '問題總數',
			SCORE_NOT_AVAILABLE: '沒有分數可以顯示',
			MAX_SCORE: '每個標籤的滿分為100',
			NO_QUESTION: '沒有問題可以顯示',
			BACK_TO_VIEW_INTERVIEW: '返回查看所有面試',
			SCORE: '總分',
			QUESTION_DETAIL: ['作答時長', '答題狀態'],
			CORRECT: '正確',
			PARTIALLY: '部份正確',
			WRONG: '錯誤',
			ANSWER_OPTIONS: '選項內容',
			FINAL_ANSWER: '最終作答內容',
			HISTORY_ANSWER: '歷史作答內容',
			YES: '正確',
			NO: '不正確'
		},
		TOOLTIP: {
			NAME: '- 使用空格分隔名字和姓氏 <br/>- 請不要使用任何特殊字符',
			EMAIL: '受訪者開始測試的帳戶',
			LABELS: '- 創建面試問題的類別 <br/>- 請選擇超過 3 個標籤 <br/>- 輸入關鍵字或從列表中選擇',
			TIME_OF_TEST: '- 測試總時間 <br/>- 使用分鐘',
			COPY_TO_CLIPBOARD: '複製到剪貼板',
			COPIED: '已複製!'
		}
	},
	QUICKUSER: {
		HI: '嗨',
		USER_PROFILE: '使用者資料',
		MY_PROFILE: '我的資料',
		ACCOUNT_SETTINGS: '帳號管理與其他',
		UPDATE: '更新'
	},
	AUTH: {
		GENERAL: {
			OR: '要么',
			SUBMIT_BUTTON: '提交',
			NO_ACCOUNT: '帳號不存在',
			SIGNUP_BUTTON: '使用者註冊',
			SIGNOUT_BUTTON: '登出',
			FORGOT_BUTTON: '忘記密碼',
			BACK_BUTTON: '返回',
			PRIVACY: '隱私政策',
			LEGAL: '法規',
			CONTACT: '聯絡我們'
		},
		LOGIN: {
			WELCOME: '歡迎使用賽鐸面試應用程式',
			TITLE: '請登入以使用網站',
			BUTTON: '登入',
			FAIL: '用戶名稱或密碼錯誤'
		},
		INPUT: {
			EMAIL: '信箱',
			FULLNAME: '全名',
			PASSWORD: '密碼',
			CONFIRM_PASSWORD: '確認密碼'
		},
		VALIDATION: {
			REQUIRED_USERNAME: '請輸入用戶名稱',
			REQUIRED_PASSWORD: '請輸入密碼',
			INVALID_EMAIL: '不符合信箱格式'
		}
	},
	INTERVIEW: {
		TITLE: '請輸入信箱<br />準備好後開始進行測驗',
		FAIL: '電子信箱格式有誤'
	},
	ECOMMERCE: {
		COMMON: {
			SEARCH: '搜尋'
		},
		CUSTOMERS: {
			CUSTOMERS: '顧客',
			CUSTOMERS_LIST: '顧客名單'
		}
	},
	DASHBOARD: {
		NEW_INTERVIEW: '新增面試',
		NEW_QUESTION: '新增問題',
		VIEW_DETAIL: '詳細資料',
		ACTIVATE_STATUS: '面試狀態總覽',
		ACTIVATE_STATUS_CATEGORIES: ['完成', '待啟動', '進行中'],
		QUESTION_ANALYSIS: '題目',
		INTERVIEW_LIST: '面試者列表',
		INTERVIEW_TOOLTIP_HEADING: '此處顯示最新的三筆面試資訊',
		INTERVIEW_TOOLTIP_DATEFORMAT: '格式：西元年-月-日',
		ERROR: '取得圖表資料時發生錯誤',
		RATIO: '正確率'
	},
	INDEXEDDB: {
		NO_SUPPORT: '此瀏覽器不支援indexedDB，部份資料可能無法離線存取',
		OFFLINE: '您目前已離線',
		OFFLINE_NO_RESOURCE: '您目前已離線，無法存取資源',
		ONLINE: '已連上網路',
		ONLINE_REFRESH: '已連上網路，重新整理以獲取最新資源'
	},
	MULTISELECT: {
		SELECTED: '已選擇'
	},
	SWEETALERT: {
		CONFIRM_DELETE: {
			TITLE: '您確定嗎？',
			TEXT: '此動作無法復原',
			CONFIRM_BUTTON_TEXT: '發送',
			SUCCESS_TITLE: '已刪除',
			SUCCESS_TEXT_DELETE: '{name}已刪除',
			ERROR_TEXT_DELETE: '刪除失敗，可能是網路或伺服器所造成的錯誤，請稍後再試',
			INTERVIEW_DELETE_ERROR_MESSAGE: '未能刪除面試，此面試正在進行中',
			LABEL_DELETE_ERROR_MESSAGE: '未能刪除標籤，此標籤已被其他問題所使用',
			QUESTION_DELETE_ERROR_MESSAGE: '刪除問題失敗，此問題正被使用中'
		},
		CANCEL_BUTTON_TEXT: '取消'
	}
};
