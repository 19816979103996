// ESP
export const locale = {
	TRANSLATOR: {
		SELECT: 'eligir la idioma'
	},
	SUBJECT: {
		LABEL: 'La Entiqueta',
		QUESTION: 'La Pregunta',
		INTERVIEW: 'La Entrevista',
		USER: 'El Usario'
	},
	MENU: {
		DASHBOARD: 'Tablero',
		NEW: 'Neuvo',
		ACTIONS: 'acciones',
		CREATE_POST: 'Create un nuevo puesto',
		PAGES: 'Páginas',
		FEATURES: 'Características',
		APPS: 'Apps',
		QUESTIONS: {
			QUESTIONS: 'Preguntas',
			CREATE_QUESTION: 'Crear Pregunta',
			VIEW_QUESTIONS: 'Ver Pregunatas',
			EDIT_QUESTIONS: 'Editar Pregunta'
		},
		LABELS: {
			LABELS: 'Etiquetas',
			CREATE_LABEL: 'Crear una Etiqueta',
			EDIT_LABEL: 'Editar Etiqueta'
		},
		USERS: {
			USERS: 'Usuarios',
			CREATE_USER: 'Crear Usario',
			EDIT_USER: 'Editar Usario'
		},
		INTERVIEWS: {
			INTERVIEWS: 'Entrevistas',
			CREATE_INTERVIEW: 'Crear una Entrevista',
			VIEW_INTERVIEWS: 'Ver Entrevistas',
			INTERVIEW_RESULT: 'Resultado de entrevista'
		}
	},
	QUESTIONS: {
		CREATE_QUESTIONS: {
			LABEL: {
				QUESTION: 'Descripción',
				ANSWERS: 'Respuestas',
				LABEL: 'Etiquetas'
			},
			PLACEHOLDER: {
				LABEL_PLACEHOLDER: 'Agregar nueva etiqueta',
				MULTISELECT_PLACEHOLDER: 'Buscar o agregar una etiqueta',
				SINGLESELECT_PLACEHOLDER: 'Elige nivel'
			},
			BUTTON: {
				ADD_ANSWER: 'Agregar respuesta',
				CREATE_QUESTION: 'Crear Pregunta',
				BACK: 'Volver',
				UPDATE_QUESTION: 'Actualizar Pregunta'
			},
			TOOLTIP: {
				LABELS: '- La categoría de la Pregunta <br/>- Elegir al menos una etiqueta'
			}
		},
		VIEW_QUESTIONS: {
			VIEW_ANSWERS: 'Mostrar respuestas',
			EDIT_ANSWERS: 'Editar Respuestas',
			VIEW_LABELS: 'Mostrar Etiquetas',
			GET_PAGE_LINK: 'Obtener enlace de página',
			RESET: 'Reset',
			ADDLABEL_MESSAGE: 'Agregar Etiquetas para llegar preguntas!',
			LOADED_MESSAGE: 'Preguntas cargadas exitosamente.',
			PLACEHOLDER: {
				MULTISELECT_PLACEHOLDER: 'Buscar preguntas con etiquetas'
			}
		},
		ALERT: {
			VALIDATION_ERROR: 'Error de validacion',
			ANSWER_EMPTY_MESSAGE: 'Rellenar este campo obilgatorio',
			ANSWER_NOEMPTY_MESSAGE: 'Este campo no deber sería vacíoAnswer description shall not be empty',
			ANSWERS_MINIMUM_MESSAGE: 'El mínimo de respuestas es dos',
			ANSWERS_MAXIMUM_MESSAGE: 'El maximo de respuestas es seis',
			ANSWERS_NOVALID_MESSAGE: 'Elegir al menos una respuesta',
			ANSWERS_DUPLICATE_MESSAGE: 'Hay duplicada respuesta',
			ANSWERS_UPDATE_SUCCESS: 'Respuestas actualizadas exitosamente',
			ANSWERS_CANT_MODIFY: 'solo puede modificar la descripción de las respuestas mientras edita la pregunta',
			NOT_SAVED_MESSAGE: 'La modificación no se ha guardado',
			API_ERROR_MESSAGE: 'No se puede obtener el recurso que requerido',
			NO_QUESTION: 'No hay ninguna pregunta para mostrar',
			LABEL_NOSELECT_MESSGE: 'Debe seleccionar al menos una etiqueta',
			LABEL_NOVALID_MESSGE: 'No puede contener ningún número o espacio en la Etiqueta ',
			LABEL_MAXIMUM_MESSGE: 'La longitud de la etiqueta no exceder las 214 letras.',
			LABEL_GET_ERROR_MESSAGE: 'No se puede obtener el recurso de etiqueta del servidor, puede ser un error de internet servidor, inténtelo tarde por favor.',
			QUESTION_EMPTY_MESSAGE: 'La descripción de la pregunta no puede sería vacía',
			QUESTION_CREATE_MESSAGE: 'Pregunta creada exitosamente',
			QUESTION_CREATE_ERROR_MESSAGE: 'No se pudo crear la pregunta, puede ser un error de internet o servidor, inténtelo tarde por favor.',
			QUESTION_UPDATE_MESSAGE: 'La Pregunta actualizada exitosamente',
			QUESTION_UPDATE_ERROR_MESSAGE: 'No se pudo actualizar la pregunta, puede ser un error de internet o servidor, inténtelo tarde por favor.',
			QUESTION_UPLOAD_SUCCESS: 'El archivo {file1} se cargó exitosamente como {file2}.'
		},
		BUTTON: {
			NEW_QUESTION: 'Nueva Pregunta',
			ADD_ANSWER: 'Agregar Respuesta',
			UPDATE_QUESTION: 'Actualizar Pregunta',
			UPLOAD_QUESTIONS_BULK: 'Carga Masiva',
			UPDATE_ANSWERS: 'Actualizar Respuesta',
			RESET: 'Reiniciar'
		}
	},
	LABELS: {
		PAGE_TITLE: 'Etiqueta',
		BUTTON_NEW_LABEL: 'Nueva Etiqueta',
		NO_LABEL_TEXT: 'No se encontraron etiquetas',
		TOOLTIP: {
			EDIT: 'Editar',
			DELETE: 'eliminar'
		},
		POPOVER: {
			TEXT: '¿eliminar esta etiqueta?',
			CANCEL: 'Cancelar',
			OK: 'OK'
		},
		FORM: {
			CREATE_PAGE_TITLE: 'Crear Etiqueta',
			EDIT_PAGE_TITLE: 'Editar Etiqueta',
			LABEL_TITLE: 'Título',
			LABEL_DESCRIPTION: 'Descripción',
			LABEL_COLOR: 'Color de fondo',
			LABEL_REMINDER: 'Use mayúsculas, use subrayado para separar dos palabras',
			BUTTON_CREATE: 'Crear',
			BUTTON_CANCEL: 'Cancelar',
			BUTTON_SAVE: 'Guardar'
		},
		TABLE: {
			NAME: 'NOMBRE',
			DESCRIPTION: 'DESCRIPCIÓN',
			ACTION: 'ACCIÓN'
		},
		ALERT: {
			BAN_CHARACTER: 'El nombre de la etiqueta contiene caracteres especiales no válidos',
			LIMIT_LENGTH: 'La etiqueta necesita {min} - {max} caracteres',
			BAN_DUPLICATED: 'Este nombre de etiqueta ya existe',
			SERVER_ERROR: 'Error de intenet o servidor. inténtelo tarde por favor.',
			GOOD: 'Muy bien',
			SUCCESS: 'Guardado exitosamente!',
			DELETED: 'Borrado exitosamente!'
		},
		COLORPALETTE: {
			GREEN_CYAN: 'Verde-cian',
			DARK_SEA_GREEN: 'verde oscura',
			GREEN_SCREEN: 'Pantalla verde',
			BLUE_GRAY: 'Gris azulado',
			BLUE: 'Azul',
			DARL_VIOLET: 'Violeta oscuro',
			ROSE_RED: 'Rojo',
			MAGENTA_PINK: 'Rosa magenta',
			TITANIUM_YELLOW: 'Amarillo titanio',
			CARROT_ORANGE: 'Naranja zanahoria'
		}
	},
	USERS: {
		PAGE_TITLE: {
			CREATE_USER: 'Crear Usario',
			MANAGE_USERS: 'Usario',
			EDIT_USER: 'Editar Usario'
		},
		TABLE: {
			NAME: 'NOMBRE',
			EMAIL: 'EMAIL',
			IS_ACTIVE: 'ACTIVADO',
			IS_SUPERUSER: 'SUPERUSUARIO',
			ACTION: 'ACCIÓN'
		},
		BUTTON_NEW_USER: 'Neuvo Usario',
		FORM: {
			EMAIL: 'Email',
			NAME: 'Nombre',
			PASSWORD: 'Contraseña',
			NEW_PASSWORD: 'Nueva Contraseña',
			CONFIRM_PASSWORD: 'Confirmar Contraseña',
			ROLE: 'Papel',
			CONSULTANT: 'Consultor',
			ADMIN: 'Admin',
			CONSULTANT_TEXT: 'Consultores de empresa',
			ADMIN_TEXT: 'Administradores de empresa',
			IS_ACTIVE: 'Este usuario está activo',
			NOT_ACTIVE: 'Este usuario no está activo',
			ACTIVE: 'Activo',
			BUTTON_CANCEL: 'Cancelar',
			BUTTON_SAVE: 'Guardar',
			CHANGE_PASSWORD: 'Cambiar la contraseña',
			YOU: 'Tu'
		},
		ALERT: {
			REQUIRED_FIELD: 'Rellenar este campo obilgatorio',
			FORMAT_NAME: 'Incorrecto formato',
			LIMIT_LENGTH: 'Este campo necesito {min} - {max}  caracteres',
			FORMAT_EMAIL: 'este email es invalido',
			CONFIRM_PASSWORD: 'Incorrecto contraseña',
			SERVER_ERROR: 'Error de intenet o servidor. inténtelo tarde por favor.',
			GREAT: 'Genial!',
			SUCCESS: 'Guardado exitosamente!',
			DELETED: 'Borrado exitosamente!',
			ERROR: 'No tienes suficientes privilegios',
			FAIL: 'Este email ya existía'
		},
		TOOLTIP: {
			EDIT: 'Editar',
			DELETE: 'Borrar',
			EMAIL: '- Cada email solo puede registrarse una vez',
			PASSWORD: '- Poner 5 a 20 caracteras <br/>- Número y caracteras especial',
			CONFIRM_PASSWORD: 'Ingrese la contraseña otra vez',
			ACTIVE: '- El defecto es activo <br/>- Se puede editar en la página "Editar usuario".',
			INACTIVE: '- El usuario inactivo no puede iniciar sesión'
		}
	},
	INTERVIEWS: {
		FORM: {
			TITLE: 'Crear Entrevista',
			NAME: 'Nombre',
			EMAIL: 'Email',
			AMOUNT_OF_QUESTIONS: 'Preguntas',
			QUESTION_UNIT: 'temas',
			TIME_OF_TEST: 'Tiempo',
			TEST_TIME_UNIT: 'minuto',
			OUTCOME_OF_EXAM: 'Puntuación',
			VERY_HARD: '<span class="font-weight-bolder text-danger">Muy dificil</span>',
			HARD: '<span class="font-weight-bolder text-danger">Dificil</span>',
			MEDIUM: '<span class="font-weight-bolder text-success">Normal</span>',
			EASY: '<span class="font-weight-bolder text-success">facil</span>',
			JUNIOR: 'Júnior',
			SENIOR: 'Senior',
			LABELS: 'Etiqueta',
			MULTISELECT_PLACEHOLDER: 'Buscar con palabra clave o elegir etiquetas',
			LINK: 'enlace',
			COPY: 'copiar',
			BACK: 'Volver',
			ENTER_DETAILS: 'Ingrese detalles',
			REVIEW_AND_SUBMIT: 'Revisar y enviar',
			REVIEW_DETAILS_AND_SUBMIT: 'Revise sus detalles y envía',
			NEXT_STEP: 'Siguiente',
			PREVIOUS: 'Volver',
			CONTINUE_CREATE: 'Continuar Crear',
			MINS: 'Min',
			SECS: 'Seg'
		},
		ALERT: {
			REQUIRED_FIELD: 'Rellenar este campo obilgatorio',
			FORMAT_NAME: 'Incorrecto formato',
			FORMAT_EMAIL: 'este email es invalido',
			FORMAT_NUMBER: 'Rellenar este campo con número solo',
			SERVER_ERROR: 'Error de intenet o servidor. inténtelo tarde por favor.',
			LABEL_NOSELECT_MESSGE: 'Debe elegir más de una etiquetas',
			CREATE_SUCCESS: 'Entrevista creada con exitosamente',
			CREATE_FAIL: 'No se pudo crear la entrevista, puede ser un error de internet servidor, inténtelo tarde por favor.',
			DEFAULT_VALUE_CHANGED: 'Consciente de que ha cambiado el valor predeterminado'
		},
		SEARCH_BANNER: {
			TITLE: 'Buscar entrevistas',
			SUBTITLE: 'Observar el análisis y el desempeño del entrevistador.',
			NEW_INTERVIEW: 'Neuvo entrevista'
		},
		TABLE: {
			THEAD: ['Estado', 'Entrevistador', 'Respuestas', 'nota', 'código', 'Etiquetas', 'Acción', 'Examen Fecha'],
			STATUS: {NOT_STARTED: 'No iniciar', FINISHED: 'finalizado', TESTING: 'En Examen'},
			DISPLAY_RECORDS: 'Mostrar {number} de {total} documento',
			LOADING: 'embarque...',
			DATA_IN_SEQUENCE: 'Datos en secuencia',
			CORRECT: 'Correcto',
			PARTIAL: 'Parcialmente correcto',
			WRONG: 'Incorrecto',
			NOT_ANSWERED: 'No respondido',
			NO_INTERVIEW: 'No hay entrevistas para mostrar',
			NOT_DONE: 'No has hecho examen,<br/> no hay ninguno dato',
			ONGOING: 'No se puede borrar la<br/> entrevista en curso'
		},
		RESULT: {
			MULTIPLE_IP: 'múltiples ip detectadas',
			CREATOR: 'Creador de Exámenes',
			NOT_AVAILABLE: 'No disponible',
			EXAM_DATE: 'Examen Fecha',
			GIVEN_TIME: 'Tiempo dado',
			TAKEN_TIME: 'Tiempo usado',
			TIME_NOT_AVAILABLE: 'El Tiempo no es disponible',
			GIVEN_QUESTIONS: 'Preguntas dadas',			
			MINUTES: 'Minutos',
			QUESTIONS: 'Preguntas',
			NO_QUESTION: 'No hay ninguna pregunta para mostrar',
			TOTAL_QUESTION: 'Todas Preguntas',
			MAX_SCORE: 'Máxima puntuación por etiqueta: 100',
			SCORE_NOT_AVAILABLE: 'La puntuación no está disponible',
			BACK_TO_VIEW_INTERVIEW: 'Volver a Entrevistas',
			SCORE: 'Nota',
			QUESTION_DETAIL: ['Tiempo usado', ' Estado de respuesta'],
			CORRECT: 'CORRECTO',
			PARTIALLY: 'Parcialmente correcto',
			WRONG: 'INCORRECTO',
			ANSWER_OPTIONS: 'Opciones de respuesta',
			FINAL_ANSWER: 'Respuesta final',
			HISTORY_ANSWER: 'Respuesta de historia',
			YES: 'Sí',
			NO: 'No'
		},
		TOOLTIP: {
			NAME: '- Pone el espacio para separar el nombre y el apellido <br/>- No aceptar carácter especial',
			EMAIL: 'Información esencial para iniciar el examen',
			LABELS: '- Crear una pregunta de entrevista con las etiquetas correspondientes <br/>- Elegir máas de 3 etiquetas <br/>- Ingrese la palabra clave o elegir desde la lista',
			TIME_OF_TEST: '- Tiempo total del examen<br/>- Unit: Minuto',
			COPY_TO_CLIPBOARD: 'Copiar al portapapeles',
			COPIED: 'Copiado!'
		}
	},
	QUICKUSER: {
		HI: 'Hola',
		USER_PROFILE: 'Usario perfil',
		MY_PROFILE: 'Mi Perfil',
		ACCOUNT_SETTINGS: 'Configuración cuenta y más',
		UPDATE: 'actualizar'
	},
	AUTH: {
		GENERAL: {
			OR: 'O',
			SUBMIT_BUTTON: 'Enviar',
			NO_ACCOUNT: '¿No tienes una cuenta?',
			SIGNUP_BUTTON: 'Inscribirse',
			SIGNOUT_BUTTON: 'desconectar',
			FORGOT_BUTTON: 'olvidar contraseña',
			BACK_BUTTON: 'Volver',
			PRIVACY: 'Intimidad',
			LEGAL: 'Legal',
			CONTACT: 'Contacto'
		},
		LOGIN: {
			WELCOME: 'Bienvenido a la Entrevistas App de SEIDOR',
			TITLE: 'inicie sesión para continuar',
			BUTTON: 'Iniciar sesión',
			FAIL: 'incorrecto nombre de usuario o contraseña'
		},
		INPUT: {
			EMAIL: 'Email',
			FULLNAME: 'Nombre completo',
			PASSWORD: 'contraseña',
			CONFIRM_PASSWORD: 'Confirmar Contraseña'
		},
		VALIDATION: {
			REQUIRED_USERNAME: 'El nombre del usario es requerido',
			REQUIRED_PASSWORD: 'La contraseña es requerido',
			INVALID_EMAIL: 'El valor no es una válida dirección de email.'
		}
	},
	INTERVIEW: {
		TITLE: 'Introduces tu email y<br />empiezas la examen cuando estarías listo',
		FAIL: 'Has ingresado un invalido email'
	},
	ECOMMERCE: {
		COMMON: {
			SEARCH: 'Buscar'
		},
		CUSTOMERS: {
			CUSTOMERS: 'Clientes',
			CUSTOMERS_LIST: 'Clientes lista'
		}
	},
	DASHBOARD: {
		NEW_INTERVIEW: 'Nueva entrevista',
		NEW_QUESTION: 'Nueva Pregunta',
		VIEW_DETAIL: 'Ver Detalles',
		ACTIVATE_STATUS: 'Estado de entrevistas',
		ACTIVATE_STATUS_CATEGORIES: ['Terminado', 'En esperar', 'En curso'],
		QUESTION_ANALYSIS: 'preguntas',
		INTERVIEW_LIST: 'Lista de entrevistas',
		INTERVIEW_TOOLTIP_HEADING: 'Solo se muestran 3 entrevistas recientes',
		INTERVIEW_TOOLTIP_DATEFORMAT: 'Formato: año-mes-día',
		ERROR: 'Hay un error al obtener datos.',
		RATIO: 'Ratio'
	},
	MULTISELECT: {
		SELECTED: 'Seleccionado'
	},
	INDEXEDDB: {
		NO_SUPPORT: 'Este navegador no es compatible con IndexedDB, algunos datos pueden no estar disponibles sin conexión',
		OFFLINE: 'Actualmente estás desconectado',
		OFFLINE_NO_RESOURCE: 'Actualmente estás desconectado, no se puede descargar los recursos',
		ONLINE: 'Internet conectado',
		ONLINE_REFRESH: 'Internet conectado, actualizar la página para obtener el último recurso'
	},
	SWEETALERT: {
		CONFIRM_DELETE: {
			TITLE: 'Seguro?',
			TEXT: 'No podrás revertir esto!',
			CONFIRM_BUTTON_TEXT: 'Si, bórralo!',
			SUCCESS_TITLE: 'Se ha borrado!',
			SUCCESS_TEXT_DELETE: '{name} ha borrado.',
			ERROR_TEXT_DELETE: 'Error al borrar, puede ser un error de internet servidor, inténtelo tarde por favor.',
			INTERVIEW_DELETE_ERROR_MESSAGE: 'No se pudo borrar la entrevista, la entrevista está en curso',
			LABEL_DELETE_ERROR_MESSAGE: 'No se pudo borrar la etiqueta, la etiqueta ya se ha usado en otras preguntas',
			QUESTION_DELETE_ERROR_MESSAGE: 'No se pudo borrar la pregunta, la pregunta todavía está en uso'
		},
		CANCEL_BUTTON_TEXT: 'Cancelar'
	}
};
