import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IUserProfile } from '@/interfaces';

export const getters = {
    hasAdminAccess: (state: MainState):boolean| null=> {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    loginError: (state: MainState):boolean => state.logInError,
    userProfile: (state: MainState):IUserProfile|null => state.userProfile,
    token: (state: MainState):string => state.token,
    isLoggedIn: (state: MainState):boolean|null => state.isLoggedIn,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
