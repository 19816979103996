
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import InterviewHeader from '@/view/layout/header/InterviewHeader.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import Loader from '@/view/content/Loader.vue';
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from '@/core/services/store/module/htmlclass.module.js';

@Component({
	components: {
		InterviewHeader,
		Loader
	},
	computed: {
		...mapGetters(['pageTitle', 'layoutConfig'])
	}
})
export default class InterviewLayout extends Vue {
	[x: string]: any;
	public beforeMount(): void {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	}

	public async mounted(): Promise<void> {
		this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
	}
	/**
	 * Check if the page loader is enabled
	 * @returns {boolean}
	 */
	get loaderEnabled(): boolean {
		return !/false/.test(this.layoutConfig('loader.type'));
	}

	/**
	 * Check if container width is fluid
	 * @returns {boolean}
	 */
	get contentFluid(): boolean {
		return this.layoutConfig('content.width') === 'fluid';
	}

	/**
	 * Page loader logo image using require() function
	 * @returns {string}
	 */
	get loaderLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('loader.logo');
	}
}
