// USA
export const locale = {
	TRANSLATOR: {
		SELECT: 'Select your language'
	},
	SUBJECT: {
		LABEL: 'The label',
		QUESTION: 'The Question',
		INTERVIEW: 'The interview',
		USER: 'The user'
	},
	MENU: {
		DASHBOARD: 'Dashboard',
		NEW: 'new',
		ACTIONS: 'Actions',
		CREATE_POST: 'Create New Post',
		PAGES: 'Pages',
		FEATURES: 'Features',
		APPS: 'Apps',
		QUESTIONS: {
			QUESTIONS: 'Questions',
			CREATE_QUESTION: 'Create Question',
			VIEW_QUESTIONS: 'View Questions',
			EDIT_QUESTIONS: 'Edit Question'
		},
		LABELS: {
			LABELS: 'Labels',
			CREATE_LABEL: 'Create Label',
			EDIT_LABEL: 'Edit Label'
		},
		USERS: {
			USERS: 'Users',
			CREATE_USER: 'Create User',
			EDIT_USER: 'Edit User'
		},
		INTERVIEWS: {
			INTERVIEWS: 'Interviews',
			CREATE_INTERVIEW: 'Create Interview',
			VIEW_INTERVIEWS: 'View Interviews',
			INTERVIEW_RESULT: 'Interview Result'
		}
	},
	QUESTIONS: {
		CREATE_QUESTIONS: {
			LABEL: {
				QUESTION: 'Description',
				ANSWERS: 'Answers',
				LABEL: 'Labels',
			},
			PLACEHOLDER: {
				LABEL_PLACEHOLDER: 'Add this as new label',
				MULTISELECT_PLACEHOLDER: 'Search or add a label',
				SINGLESELECT_PLACEHOLDER: 'Pick a level'
			},
			BUTTON: {
				ADD_ANSWER: 'Add Answer',
				CREATE_QUESTION: 'Create Question',
				BACK: 'Back',
				UPDATE_QUESTION: 'Update Question'
			},
			TOOLTIP: {
				LABELS: '- The category of question <br/>- Please select at least one label'
			}
		},
		VIEW_QUESTIONS: {
			VIEW_ANSWERS: 'Show Answers',
			EDIT_ANSWERS: 'Edit Answers',
			VIEW_LABELS: 'Show Labels',
			GET_PAGE_LINK: 'Get Page Link',
			RESET: 'Reset',
			ADDLABEL_MESSAGE: 'Add label to get questions!',
			LOADED_MESSAGE: 'Questions loaded successfully.',
			PLACEHOLDER: {
				MULTISELECT_PLACEHOLDER: 'Search Questions with Labels'
			}
		},
		ALERT: {
			VALIDATION_ERROR: 'Validation Error',
			ANSWER_EMPTY_MESSAGE: 'Answer description is mandatory',
			ANSWER_NOEMPTY_MESSAGE: 'Answer description shall not be empty',
			ANSWERS_MINIMUM_MESSAGE: 'The minimum number of answers is two',
			ANSWERS_MAXIMUM_MESSAGE: 'The maximum number of answers is six',
			ANSWERS_NOVALID_MESSAGE: 'There must be at least a correct answer',
			ANSWERS_DUPLICATE_MESSAGE: 'There are duplicate answers',
			ANSWERS_UPDATE_SUCCESS: 'Answers update success',
			ANSWERS_CANT_MODIFY: "You can only modify answers's description while editing question",
			NOT_SAVED_MESSAGE: 'Modification has not been saved',
			API_ERROR_MESSAGE: 'Cannot get the required resource',
			NO_QUESTION: "There aren't any questions",
			LABEL_NOSELECT_MESSGE: 'Must select at least one label',
			LABEL_NOVALID_MESSGE: 'Label can not contain any number or space',
			LABEL_MAXIMUM_MESSGE: 'The length of the label must not exceed 214 letters',
			LABEL_GET_ERROR_MESSAGE: 'Unable to get label resource from server, it might be network or server error, please try again later',
			QUESTION_EMPTY_MESSAGE: "The question's description can not be empty",
			QUESTION_CREATE_MESSAGE: 'Question create successfully',
			QUESTION_CREATE_ERROR_MESSAGE: 'Failed to create question, it might be network or server error, please try again later',
			QUESTION_UPDATE_MESSAGE: 'Question update successfully',
			QUESTION_UPDATE_ERROR_MESSAGE: 'Failed to update question, it might be network or server error, please try again later',
			QUESTION_UPLOAD_SUCCESS: ' File {file1} was successfully uploaded as {file2}.'
		},
		BUTTON: {
			NEW_QUESTION: 'New Question',
			ADD_ANSWER: 'Add Answer',
			UPDATE_QUESTION: 'Update Question',
			UPLOAD_QUESTIONS_BULK: 'Bulk Upload',
			UPDATE_ANSWERS: 'Update Answers',
			RESET: 'Reset'
		}
	},
	LABELS: {
		PAGE_TITLE: 'Labels',
		BUTTON_NEW_LABEL: 'New label',
		NO_LABEL_TEXT: 'No labels found',
		TOOLTIP: {
			EDIT: 'Edit',
			DELETE: 'Delete'
		},
		POPOVER: {
			TEXT: 'Do you want to delete this Label?',
			CANCEL: 'Cancel',
			OK: 'OK'
		},
		FORM: {
			CREATE_PAGE_TITLE: 'Create Label',
			EDIT_PAGE_TITLE: 'Edit Label',
			LABEL_TITLE: 'Title',
			LABEL_DESCRIPTION: 'Description',
			LABEL_COLOR: 'Background color',
			LABEL_REMINDER: 'Use Uppercase, use underscore to seperate two words',
			BUTTON_CREATE: 'Create',
			BUTTON_CANCEL: 'Cancel',
			BUTTON_SAVE: 'Save'
		},
		TABLE: {
			NAME: 'NAME',
			DESCRIPTION: 'DESCRIPTION',
			ACTION: 'ACTION'
		},
		ALERT: {
			BAN_CHARACTER: 'Label name contains invalid special character(s)',
			LIMIT_LENGTH: 'Your label must be {min} - {max} characters long',
			BAN_DUPLICATED: 'This label name already exist',
			SERVER_ERROR: 'Network or server error, please try again later',
			GOOD: 'Looks Good',
			SUCCESS: 'Saved successfully!',
			DELETED: 'Deleted successfully!'
		},
		COLORPALETTE: {
			GREEN_CYAN: 'Green-cyan',
			DARK_SEA_GREEN: 'Dark sea green',
			GREEN_SCREEN: 'Green screen',
			BLUE_GRAY: 'Blue-gray',
			BLUE: 'Blue',
			DARL_VIOLET: 'Dark violet',
			ROSE_RED: 'Rose red',
			MAGENTA_PINK: 'Magenta-pink',
			TITANIUM_YELLOW: 'Titanium yellow',
			CARROT_ORANGE: 'Carrot orange'
		}
	},
	USERS: {
		PAGE_TITLE: {
			CREATE_USER: 'Create User',
			MANAGE_USERS: 'Users',
			EDIT_USER: 'Edit User'
		},
		TABLE: {
			NAME: 'NAME',
			EMAIL: 'EMAIL',
			IS_ACTIVE: 'ACTIVE',
			IS_SUPERUSER: 'SUPERUSER',
			ACTION: 'ACTION'
		},
		BUTTON_NEW_USER: 'New user',
		FORM: {
			EMAIL: 'Email',
			NAME: 'Name',
			PASSWORD: 'Password',
			NEW_PASSWORD: 'New Password',
			CONFIRM_PASSWORD: 'Confirm Password',
			ROLE: 'Role',
			CONSULTANT: 'Consultant',
			ADMIN: 'Admin',
			CONSULTANT_TEXT: 'Best for company consultants',
			ADMIN_TEXT: 'Best for company administrators',
			IS_ACTIVE: 'This user is active',
			NOT_ACTIVE: 'This user is not active',
			ACTIVE: 'Active',
			BUTTON_CANCEL: 'Cancel',
			BUTTON_SAVE: 'Save',
			CHANGE_PASSWORD: 'Change Password',
			YOU: 'You'
		},
		ALERT: {
			REQUIRED_FIELD: 'Please fill out this field',
			FORMAT_NAME: 'The format is incorrect',
			LIMIT_LENGTH: 'This field must be {min} - {max}  characters long',
			FORMAT_EMAIL: 'You have entered an invalid email',
			CONFIRM_PASSWORD: "The password doesn't match",
			SERVER_ERROR: 'Network or server error, please try again later',
			GREAT: 'Great!',
			SUCCESS: 'Saved successfully!',
			DELETED: 'Deleted successfully!',
			ERROR: "You don't have enough privileges",
			FAIL: 'This email already exists in the system'
		},
		TOOLTIP: {
			EDIT: 'Edit',
			DELETE: 'Delete',
			EMAIL: '- Each email only can register one time',
			PASSWORD: '- Use 5 to 20 characters <br/>- Number and special character are available',
			CONFIRM_PASSWORD: 'Please enter password again',
			ACTIVE: '- Default is active <br/>- Available to edit on Edit User page',
			INACTIVE: '- Inactive user is not allow to login'
		}
	},
	INTERVIEWS: {
		FORM: {
			TITLE: 'Create Interview',
			NAME: 'Name',
			EMAIL: 'Email',
			AMOUNT_OF_QUESTIONS: 'Questions',
			QUESTION_UNIT: 'items',
			TIME_OF_TEST: 'Time',
			TEST_TIME_UNIT: 'minutes',
			OUTCOME_OF_EXAM: 'Score',
			VERY_HARD: '<span class="font-weight-bolder text-danger">Very Hard</span>',
			HARD: '<span class="font-weight-bolder text-danger">Hard</span>',
			MEDIUM: '<span class="font-weight-bolder text-success">Medium</span>',
			EASY: '<span class="font-weight-bolder text-success">Basic</span>',
			JUNIOR: 'Junior',
			SENIOR: 'Senior',
			LABELS: 'Labels',
			MULTISELECT_PLACEHOLDER: 'Enter keyword to search or select labels',
			LINK: 'Link',
			COPY: 'Copy',
			BACK: 'Back',
			ENTER_DETAILS: 'Enter Details',
			REVIEW_AND_SUBMIT: 'Review and Submit',
			REVIEW_DETAILS_AND_SUBMIT: 'Review your Details and Submit',
			NEXT_STEP: 'Next Step',
			PREVIOUS: 'Previous',
			CONTINUE_CREATE: 'Continue Create',
			MINS: 'Mins',
			SECS: 'Secs'
		},
		ALERT: {
			REQUIRED_FIELD: 'Please fill out this field',
			FORMAT_NAME: 'The format is incorrect',
			FORMAT_EMAIL: 'You have entered an invalid email',
			FORMAT_NUMBER: 'The field can only fill in numbers',
			SERVER_ERROR: 'Network or server error, please try again later',
			LABEL_NOSELECT_MESSGE: 'Must select at least one label',
			CREATE_SUCCESS: 'Interview create successfully',
			CREATE_FAIL: 'Failed to create interview, it might be network or server error, please try again later',
			DEFAULT_VALUE_CHANGED: 'Aware that you have changed the default value'
		},
		SEARCH_BANNER: {
			TITLE: 'Search Interviews',
			SUBTITLE: 'Interview and performance observation',
			NEW_INTERVIEW: 'New Interview'
		},
		TABLE: {
			THEAD: ['Status', 'Interviewee', 'Answers', 'Score', 'Interview Code', 'Labels', 'Action', 'Date Taken'],
			STATUS: {NOT_STARTED: 'Not Started', FINISHED: 'Finished', TESTING: 'In Progress'},
			DISPLAY_RECORDS: 'Displaying {number} of {total} records',
			LOADING: 'Loading...',
			DATA_IN_SEQUENCE: 'Data in sequece',
			CORRECT: 'Correct',
			PARTIAL: 'Partially Correct',
			WRONG: 'Wrong',
			NOT_ANSWERED: 'Not Answered',
			NO_INTERVIEW: 'There are no interviews to show',
			NOT_DONE: 'Test not done,<br/> no data to show',
			ONGOING: 'Cannot delete <br/> ongoing interview'
		},
		RESULT: {
			MULTIPLE_IP: 'multiple IP detected',
			CREATOR: 'Exam Creator',
			NOT_AVAILABLE: 'Not available',
			EXAM_DATE: 'Exam Date',
			GIVEN_TIME: 'Given Time',
			TAKEN_TIME: 'Time Taken',
			TIME_NOT_AVAILABLE: 'Time is not available',
			GIVEN_QUESTIONS: 'Given Questions',
			MINUTES: 'Minutes',
			QUESTIONS: 'Questions',
			NO_QUESTION: "There aren't any questions",
			TOTAL_QUESTION: 'Total Question',
			SCORE_NOT_AVAILABLE: 'Score is not available',
			MAX_SCORE: 'Max score per label: 100',
			BACK_TO_VIEW_INTERVIEW: 'Back to all interviews',
			SCORE: 'Score',
			QUESTION_DETAIL: ['Time Spent', ' Answer Status'],
			CORRECT: 'CORRECT',
			PARTIALLY: 'Partially Correct',
			WRONG: 'WRONG',
			ANSWER_OPTIONS: 'Answer Options',
			FINAL_ANSWER: 'Final Answer',
			HISTORY_ANSWER: 'History Answer',
			YES: 'Yes',
			NO: 'No'
		},
		TOOLTIP: {
			NAME: "- Use space to separate first name and last name <br/>- Please don't use any special character",
			EMAIL: 'Essential information for interviewee to enter the test',
			LABELS: '- Create interview question with corresponding labels <br/>- Please select more than 3 labels <br/>- Enter keyword or select from the list',
			TIME_OF_TEST: '- Total time for the test <br/>- Unit: Minute',
			COPY_TO_CLIPBOARD: 'Copy to clipboard',
			COPIED: 'Copied!'
		}
	},
	QUICKUSER: {
		HI: 'Hi',
		USER_PROFILE: 'User Profile',
		MY_PROFILE: 'My Profile',
		ACCOUNT_SETTINGS: 'Account settings and more',
		UPDATE: 'update'
	},
	AUTH: {
		GENERAL: {
			OR: 'Or',
			SUBMIT_BUTTON: 'Submit',
			NO_ACCOUNT: "Don't have an account?",
			SIGNUP_BUTTON: 'Sign Up',
			SIGNOUT_BUTTON: 'Sign Out',
			FORGOT_BUTTON: 'Forgot Password',
			BACK_BUTTON: 'Back',
			PRIVACY: 'Privacy',
			LEGAL: 'Legal',
			CONTACT: 'Contact'
		},
		LOGIN: {
			WELCOME: 'Welcome to SEIDOR Interview App',
			TITLE: 'Please sign in to continue',
			BUTTON: 'Sign In',
			FAIL: 'Incorrect username or password'
		},
		INPUT: {
			EMAIL: 'Email',
			FULLNAME: 'Fullname',
			PASSWORD: 'Password',
			CONFIRM_PASSWORD: 'Confirm Password'
		},
		VALIDATION: {
			REQUIRED_USERNAME: 'Username is required',
			REQUIRED_PASSWORD: 'Password is required',
			INVALID_EMAIL: 'The value is not a valid email address'
		}
	},
	INTERVIEW: {
		TITLE: 'Enter your email and<br />start the test when you are ready',
		FAIL: 'You have entered an invalid email'
	},
	ECOMMERCE: {
		COMMON: {
			SEARCH: 'Search'
		},
		CUSTOMERS: {
			CUSTOMERS: 'Customers',
			CUSTOMERS_LIST: 'Customers list'
		}
	},
	DASHBOARD: {
		NEW_INTERVIEW: 'New Interview',
		NEW_QUESTION: 'New Question',
		VIEW_DETAIL: 'View Detail',
		ACTIVATE_STATUS: 'Interviews Status',
		ACTIVATE_STATUS_CATEGORIES: ['Done', 'To do', 'In progress'],
		QUESTION_ANALYSIS: 'Questions',
		INTERVIEW_LIST: 'Interview List',
		INTERVIEW_TOOLTIP_HEADING: 'Only most recent 3 interviews are displayed',
		INTERVIEW_TOOLTIP_DATEFORMAT: 'Format: year-month-day',
		ERROR: 'There is an error while obtaining data for charts',
		RATIO: 'Ratio'
	},
	MULTISELECT: {
		SELECTED: 'Selected'
	},
	INDEXEDDB: {
		NO_SUPPORT: 'This browser does not support IndexedDB, some data may not be available offline',
		OFFLINE: "You're currently offline",
		OFFLINE_NO_RESOURCE: "You're currently offline, resource not downloadable",
		ONLINE: 'Network connected',
		ONLINE_REFRESH: 'Network connected, refresh page to get latest resource'
	},
	SWEETALERT: {
		CONFIRM_DELETE: {
			TITLE: 'Are you sure?',
			TEXT: "You won't be able to revert this!",
			CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
			SUCCESS_TITLE: 'Deleted!',
			SUCCESS_TEXT_DELETE: '{name} has been deleted.',
			ERROR_TEXT_DELETE: 'Delete failed, it might be network or server error, please try again later',
			INTERVIEW_DELETE_ERROR_MESSAGE: 'Failed to delete the interview, the interview is in progress',
			LABEL_DELETE_ERROR_MESSAGE: 'Failed to delete the label, the label is already used by other questions',
			QUESTION_DELETE_ERROR_MESSAGE: 'Failed to delete the question, the question is still in use'
		},
		CANCEL_BUTTON_TEXT: 'Cancel'
	}
};
