import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IUserProfileCreate, IUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser } from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionGetUsers(context: MainContext): Promise<void> {
        try {
            const response = await api.getUsers();
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }): Promise<void> {
        const response = await api.updateUser(payload.id, payload.user)
        if (response) {
            commitSetUser(context, response.data)
        } else {
            throw new Error;
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate): Promise<void> {
        const response = await api.createUser(payload)
        if (response) {
            commitSetUser(context, response.data);
        }
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
