
import {Component, Watch, Emit, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
	@Prop() public currentPage!: number;
	@Prop() public recordCount!: number;
	@Prop() public recordPerPage!: number;

	public get pageCount() {
		return Math.ceil(this.recordCount / +this.recordPerPage) || 1;
	}
	public gotoPage(goto: number) {
		if (0 < goto && goto <= this.pageCount) {
			this.pageChange(goto);
		}
	}
	@Emit('page-change')
	public pageChange(goto: number) {
		return goto;
	}
}
