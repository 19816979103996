
	import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
	import {IAnswer} from '@/interfaces/exam';

	@Component
	export default class QuestionAnswer extends Vue {
		private answer: IAnswer = {
			//Make class "is-invalid" true by a space key
			description: ' ',
			is_valid: false
		};

		get isEditMode() {
			return this.$route.params.id;
		}

		@Prop() private index!: number;
		@Prop({default: ' '}) private description!: string;
		@Prop({default: false}) private is_valid!: boolean;

		public mounted() {
			this.answer.description = this.description;
			this.answer.is_valid = this.is_valid;
		}

		@Watch('answer.description')
		descriptionChanged() {
			this.update();
		}

		@Watch('answer.is_valid')
		isValidChanged() {
			this.update();
		}

		@Watch('index')
		indexChanged() {
			this.update();
		}

		@Emit()
		update() {
			this.answer.description = this.answer.description.trim();
			if (this.answer.description) {
				return {
					index: this.index,
					answer: this.answer
				};
			}
		}

		@Emit()
		remove() {
			return this.index;
		}
	}
