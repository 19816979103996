
import {SET_LAYOUT_CONFIG} from '@/core/services/store/module/config.module';
import config from '@/core/config/layout.config.json';
import {Component, Mixins, Watch, Ref} from 'vue-property-decorator';
import {api} from '@/api';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {IInterview} from '@/interfaces/exam';
import eventBus from '@/assets/js/components/eventBus';
import Pagination from '@/view/content/Pagination.vue';
import swal from 'sweetalert2';
type SearchParams = {
	'name_or_mail': string | undefined;
	'score': number | undefined;
	'labels': string | undefined;
};

@Component({components: {Pagination}})
export default class viewInterviews extends Mixins(MakeToast) {
	// pagination variables
	public recordPerPage = 10;
	public currentPage = 1;
	// interview variables
	public rawInterviewList = [] as Array<IInterview>;
	readonly RATING_WIDTH = 91.5;
	public isLoading = false;
	public interviewCount = 1;
	public searchParams: SearchParams = {
		'name_or_mail': undefined,
		'score': undefined,
		'labels': undefined
	};
	public copyButtonMessage: string = this.$t('INTERVIEWS.TOOLTIP.COPY_TO_CLIPBOARD');
	public tipData = {
		title: `${this.$t('INTERVIEWS.TABLE.DATA_IN_SEQUENCE')}<br/>
				<span class="text-success">${this.$t('INTERVIEWS.TABLE.CORRECT')}</span>
				/
				<span class="text-warning">${this.$t('INTERVIEWS.TABLE.PARTIAL')}</span>
				/
				<span class="text-danger">${this.$t('INTERVIEWS.TABLE.WRONG')}</span>
				/
				<span class="text-muted">${this.$t('INTERVIEWS.TABLE.NOT_ANSWERED')}</span>`
	};
	async beforeMount() {
		this.isLoading = true;
		await this.getInterviews(this.axiosParams);
		this.isLoading = false;
	}
	mounted() {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');
		config.subheader.layout = 'searchbanner';
		this.$store.dispatch(SET_LAYOUT_CONFIG, config);
		eventBus.$on('send-params', async searchBannerParams => {
			this.searchParams.name_or_mail = searchBannerParams.string;
			this.searchParams.score = searchBannerParams.score;
			this.searchParams.labels = searchBannerParams.label;
			this.currentPage = 1;
			await this.getInterviews(this.axiosParams);
		});
	}
	beforeDestroy() {
		config.subheader.layout = 'subheader-v1';
		this.$store.dispatch(SET_LAYOUT_CONFIG, config);
		// removing eventBus listener
		eventBus.$off('send-params');
	}
	public async getInterviews(paramString: string) {
		await api
			.getInterviews(paramString)
			.then(response => {
				this.rawInterviewList = response.data.interviews;
				this.interviewCount = response.data.amount_of_interviews;
			})
			.catch((error: any) => {
				this.makeToast(this.$t('QUESTIONS.ALERT.API_ERROR_MESSAGE'), 'danger');
			});
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.remove('spinner');
	}
	get axiosParams() {
		const params = new URLSearchParams();
		params.append('start_index', ((this.currentPage - 1) * this.recordPerPage).toString());
		params.append('end_index', (this.currentPage * this.recordPerPage).toString());
		for (const [key, value] of Object.entries(this.searchParams)) {
			if (value !== undefined) params.append(key, value.toString());
		}
		return params.toString();
	}
	get interviewList() {
		if (this.rawInterviewList.length > 0) {
			const interviewList = this.rawInterviewList.map(interview => ({
				...interview,
				score: interview.result ? interview.result.total_correct_questions : 0
			}));
			return interviewList;
		} else {
			return [];
		}
	}

	// functions for Pagination
	public onPageChanged(val: number) {
		this.currentPage = val;
		this.getInterviews(this.axiosParams);
	}
	@Watch('recordPerPage')
	onRecordPerPageChanged() {
		this.currentPage = 1;
		this.getInterviews(this.axiosParams);
	}
	get infoSVG() {
		return process.env.BASE_URL + 'media/svg/icons/info-question.svg';
	}

	get compareTotalAndRecord() {
		if (this.interviewCount > this.recordPerPage) {
			return this.recordPerPage;
		}
		return this.interviewCount;
	}
	//function for interview removal
	public confirmRemove(interviewCode: number) {
		let self = this;
		swal
			.fire({
				title: this.$t('SWEETALERT.CONFIRM_DELETE.TITLE'),
				text: this.$t('SWEETALERT.CONFIRM_DELETE.TEXT'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.$t('SWEETALERT.CANCEL_BUTTON_TEXT'),
				confirmButtonText: this.$t('SWEETALERT.CONFIRM_DELETE.CONFIRM_BUTTON_TEXT'),
				heightAuto: false,
				reverseButtons: true
			})
			.then(async result => {
				if (result.isConfirmed) {
					try {
						await self.removeInterview(interviewCode);
						this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.SUCCESS_TEXT_DELETE', {name: this.$t('SUBJECT.INTERVIEW')}), 'success');
					} catch (error: any) {
						if (error.response.status === 406) {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.INTERVIEW_DELETE_ERROR_MESSAGE'), 'danger');
						} else {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.ERROR_TEXT_DELETE', {name: this.$t('SUBJECT.INTERVIEW')}), 'danger');
						}
						this.isLoading = false;
					}
				}
			});
	}
	public async removeInterview(InterviewCode: number) {
		this.isLoading = true;
		await api.deleteInterview(InterviewCode);
		await this.getInterviews(this.axiosParams);
		this.isLoading = false;
	}
	public copyToClipboard(code: string) {
		let path = `${window.location.href.split(window.location.pathname)[0]}/interviews/${code}`;
		if (navigator.clipboard && window.isSecureContext) {
			navigator.clipboard.writeText(path);
		} else {
			const textarea = document.createElement("textarea");
			textarea.textContent = path;
			textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
			document.body.appendChild(textarea);
			textarea.select()
			textarea.setSelectionRange(0, path.length);
			document.execCommand('copy');
			textarea.remove();
		}
		this.copyButtonMessage = this.$t('INTERVIEWS.TOOLTIP.COPIED');
	}
	public resetTooltipMessage() {
		this.copyButtonMessage = this.$t('INTERVIEWS.TOOLTIP.COPY_TO_CLIPBOARD');
	}
}
