
import { Component, Vue, Emit } from "vue-property-decorator";
import i18nService from "@/core/services/i18n.service";

@Component
export default class KTDropdownLanguage extends Vue {
  private languages: any = i18nService.languages;

  private selectedLanguage(e): any {
    const el = e.target.closest(".navi-link");
    const lang = el.getAttribute("data-lang");

    i18nService.setActiveLanguage(lang);

    this.languageChanged(lang);

    window.location.reload();
  }

  @Emit()
  languageChanged(lang): any {
    return this.languages.find((val) => val.lang === lang);
  }

  private isActiveLanguage(current): any {
    return this.activeLanguage === current;
  }

  get activeLanguage(): string {
    return i18nService.getActiveLanguage();
  }
}
