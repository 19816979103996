import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import './component-hooks';
Vue.config.productionTip = false;
// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
window.PerfectScrollbar = window.PerfectScrollbar || {};

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';


// API service init
ApiService.init();

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');
