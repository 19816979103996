
import {Component, Mixins, Watch, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import KTLayoutHeader from '@/assets/js/layout/base/header.js';
import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';
import {readInterview, readInterviewCode} from '@/core/services/store/interview/getters';
import {dispatchSetInterviewTime} from '@/core/services/store/interview/actions';
import {setDriftlessTimeout, clearDriftless} from 'driftless';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import swal from 'sweetalert2';
import {api} from '@/api';

@Component({
	components: {},
	computed: {
		...mapGetters(['layoutConfig', 'getClasses'])
	}
})
export default class InterviewHeader extends Mixins(MakeToast) {
	[x: string]: any;
	public hours = '0';
	public minutes = '0';
	public seconds = '0';
	public deadline: number = 0;
	public timeLeft: number = 0;
	public startDeviation = 0;
	public code = this.$route.params.code;
	// listen to whether tab is re-activated
	public awayFromExam: boolean | null = null;

	public readonly amountOfQuestions = readInterview(this.$store)!.amount_of_questions;
	get answered_question() {
		return readInterview(this.$store)!.amount_of_answered_questions;
	}
	get width() {
		return `width: ${Math.round((this.answered_question * 100) / this.amountOfQuestions)}%`;
	}
	public async getTimeFromApi() {
		await api
			.getInterview(this.code)
			.then(response => {
				dispatchSetInterviewTime(this.$store, response.data.remaining_time);
			})
			.catch(error => {
				this.errorMessage = error.response.data.detail;
			});
	}
	public async checkTabActive() {
		if (this.awayFromExam === true) {
			await api
				.getInterview(this.code)
				.then(response => {
					let timeStr = response.data.remaining_time;
					if (timeStr) {
						let timeArr = timeStr.split(':');
						this.deadline = (Number(timeArr.slice(-1)) + Number(timeArr.slice(-2, -1)) * 60 + Number(timeArr.slice(-3, -2)) * 3600) * 1000;
						this.timeLeft = this.deadline;
					}
				})
				.catch(error => {
					this.errorMessage = error.response.data.detail;
				});
		}
		if (document.hidden) this.awayFromExam = true;
		else this.awayFromExam = false;
	}
	public async created(): Promise<void> {
		let self = this;
		await this.getTimeFromApi();
		document.addEventListener('visibilitychange', this.checkTabActive);
	}
	public async mounted(): Promise<void> {
		this.getDeadline();
		this.timeLeft = this.deadline;
		KTLayoutHeader.init('kt_header', 'kt_header_mobile');
		KTLayoutHeaderMenu.init(this.$refs['kt_header_menu'], this.$refs['kt_header_menu_wrapper']);
		this.startDeviation = new Date().getMilliseconds();
		setDriftlessTimeout(this.countdown, 1);
	}
	public beforeDestroy() {
		document.removeEventListener('visibilitychange', this.checkTabActive);
		clearDriftless();
	}
	public countdown() {
		this.timeLeft = this.timeLeft - 1000;
		let seconds = Math.floor((this.timeLeft * 0.001) % 60);
		let minutes = Math.floor(((this.timeLeft * 0.001) / 60) % 60);
		let hours = Math.floor((this.timeLeft * 0.001) / 3600);
		if (this.timeLeft > 0) {
			this.hours = ('0' + hours).slice(-1);
			(this.minutes = ('0' + minutes).slice(-2)), (this.seconds = ('0' + seconds).slice(-2));
			this.startDeviation = new Date().getMilliseconds();
			setDriftlessTimeout(this.countdown, 1000);
		} else {
			this.closeExam();
		}
		if (hours === 0 && minutes === 5 && seconds === 1) {
			this.$bvToast.toast('5 minutes left, hurry up!', {
				title: 'Alert',
				variant: 'danger',
				toaster: 'b-toaster-top-center',
				solid: true,
				autoHideDelay: 5000,
				appendToast: false
			});
		}
	}
	public getDeadline(): any {
		let timeStr = readInterview(this.$store)!.remaining_time;
		let timeArr = timeStr.split(':');
		this.deadline = (Number(timeArr.slice(-1)) + Number(timeArr.slice(-2, -1)) * 60 + Number(timeArr.slice(-3, -2)) * 3600) * 1000;
	}

	/**
	 * Get extra classes for header based on the options
	 * @returns {null|*}
	 */
	get headerClasses(): any {
		const classes = this.getClasses('header');
		if (typeof classes !== 'undefined') {
			return classes.join(' ');
		}
		return null;
	}
	get menuLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.pinterview.horizontal');
	}
	/**
	 * Get extra classes for header menu based on the options
	 * @returns {null|*}
	 */
	get headerMenuClasses(): any {
		const classes = this.getClasses('header_menu');
		if (typeof classes !== 'undefined') {
			return classes.join(' ');
		}
		return null;
	}

	public closeExam() {
		this.$router.replace('/interviews/end');
		api.closeInterview(readInterviewCode(this.$store));
	}

	public confirmFinish() {
		let self = this;
		swal
			.fire({
				title: 'Send your answer',
				text: "You won't be able to come back to this exam",
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				cancelButtonText: 'Cancel',
				confirmButtonText: 'Send'
			})
			.then(function (result) {
				if (result.value) {
					self.closeExam();
				}
			});
	}
}
