import Vue from 'vue';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import VueAxios from 'vue-axios';
import { getLocalToken } from '@/utils';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init(): void {
		Vue.use(VueAxios, axios);
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader(): void {
		Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${getLocalToken()}`;
	},

	query(resource: string, params: AxiosRequestConfig | undefined): AxiosPromise {
		return Vue.axios.get(resource, params);
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource: string): AxiosPromise {
		return Vue.axios.get(`${resource}`);
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	post(resource: string, params: unknown, additionalHeader?:{ [ key:string ]: string }): AxiosPromise {
		if(additionalHeader !== undefined){
			Object.assign(additionalHeader, {'Authorization': `Bearer ${getLocalToken()}`});
		}		
		return Vue.axios.post(`${resource}`, params, { headers:additionalHeader});
	},

	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource: string, params: unknown): AxiosPromise {
		return Vue.axios.put(`${resource}`, params);
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource: string, params: unknown): AxiosPromise {
		return Vue.axios.put(`${resource}`, params);
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource: string): AxiosPromise {
		return Vue.axios.delete(resource);
	}
};

export default ApiService;
