
import {Component, Vue} from 'vue-property-decorator';
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import i18nService from '@/core/services/i18n.service';
import {readHasAdminAccess} from '@/core/services/store/main/getters';
@Component({
	components: {
		KTDropdownLanguage,
		KTQuickUser
	}
})
export default class KTTopbar extends Vue {
	private languageFlag: string = '';
	private languages: any = i18nService.languages;

	private onLanguageChanged(): void {
		this.languageFlag = this.languages.find(val => {
			return val.lang === i18nService.getActiveLanguage();
		}).flag;
	}

	get getLanguageFlag(): void {
		return this.onLanguageChanged();
	}
	get hasAdminAccess() {
		return readHasAdminAccess(this.$store);
	}
}
