
import { Component, Vue } from "vue-property-decorator";
import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";

@Component
export default class KTScrollTop extends Vue {
  public mounted(): void {
    // Init Scrolltop
    KTLayoutScrolltop.init(this.$refs["kt_scrolltop"]);
  }

  get navigationUpIcon(): any {
		return process.env.BASE_URL + "media/svg/icons/Navigation/Up-2.svg";
	}
}
