import { IUserProfile } from '@/interfaces';
import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string):void {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean):void {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean):void {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile):void {
        state.userProfile = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
