
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js';

@Component({
	computed: {
		...mapGetters(['layoutConfig', 'getClasses'])
	}
})
export default class KTHeaderMobile extends Vue {
	[x: string]: any;

	public mounted(): void {
		// Init Header Topbar For Mobile Mode
		KTLayoutHeaderTopbar.init(this.$refs['kt_header_mobile_topbar_toggle']);
	}

	get accountSettingIcon(): any {
		return process.env.BASE_URL + "media/svg/icons/General/User.svg";
	}

	/**
	 * Get header logo
	 * @returns {string}
	 */
	get headerLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.pinterview.horizontal_svg');
	}

	/**
	 * Get classes for mobile header
	 * @returns {null|*}
	 */
	get headerClasses(): any {
		const classes = this.getClasses('header_mobile');
		if (typeof classes !== 'undefined') {
			return classes.join(' ');
		}
		return null;
	}

	/**
	 * Check if the left aside menu is enabled
	 * @returns {boolean}
	 */
	get asideEnabled(): boolean {
		return !!this.layoutConfig('aside.self.display');
	}
}
