
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTMenu from "@/view/layout/header/Menu.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

@Component({
  components: {
    KTTopbar,
    KTMenu,
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
  },
})
export default class KTHeader extends Vue {
  [x: string]: any;

  public mounted(): void {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef: any = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  }
  /**
   * Check if the header menu is enabled
   * @returns {boolean}
   */
  get headerMenuEnabled(): boolean {
    return !!this.layoutConfig("header.menu.self.display");
  }

  /**
   * Get extra classes for header based on the options
   * @returns {null|*}
   */
  get headerClasses(): any {
    const classes = this.getClasses("header");
    if (typeof classes !== "undefined") {
      return classes.join(" ");
    }
    return null;
  }

  /**
   * Get extra classes for header menu based on the options
   * @returns {null|*}
   */
  get headerMenuClasses(): any {
    const classes = this.getClasses("header_menu");
    if (typeof classes !== "undefined") {
      return classes.join(" ");
    }
    return null;
  }
}
