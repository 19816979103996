
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {dispatchUpdateUserProfile} from '@/core/services/store/main/actions';
import {dispatchGetUsers, dispatchUpdateUser} from '@/core/services/store/admin/actions';
import {IUserProfileUpdate} from '@/interfaces';
import {store} from '@/core/services/store';
import {readHasAdminAccess, readUserProfile} from '@/core/services/store/main/getters';
import {readAdminOneUser} from '@/core/services/store/admin/getters';

const routeGuardAdmin = async (to, from, next) => {
	if (readHasAdminAccess(store) || readUserProfile(store)!.id === to.params.id) {
		next();
	} else {
		next(from);
	}
};
@Component
export default class EditUser extends Mixins(MakeToast) {
	private email = '';
	private full_name = '';
	private is_superuser = false;
	private is_active = true;
	private password1 = '';
	private password2 = '';
	private isLoading = false;
	private setPassword = false;
	private allValid: Array<boolean> = [];
	private vFields: any = {
		name: {
			is_valid: null,
			error_feedback: ''
		},
		email: {
			is_valid: null,
			error_feedback: ''
		},
		password1: {
			is_valid: null,
			minLength: 5,
			maxLength: 20,
			error_feedback: ''
		},
		password2: {
			is_valid: null,
			error_feedback: ''
		}
	};
	get user() {
		if(this.hasAdminAccess) {
			return readAdminOneUser(this.$store)(+this.$route.params.id)
		} else {
			return readUserProfile(this.$store);
		}
	}
	get isSelf() {
		return readUserProfile(this.$store)!.id == Number(this.$route.params.id); 
	}
	get hasAdminAccess() {
		return readHasAdminAccess(this.$store);
	}
	get validToSubmit() {
		this.getValidList();
		const checker = (arr: Array<any>) => arr.every(Boolean);
		return checker(this.allValid);
	}

	@Watch('$route.params.id')
	whenUserIdChanged(){
		this.reset();
	}

	public beforeRouteEnter(to, from, next) {
		routeGuardAdmin(to, from, next);
	}
	public beforeRouteUpdate(to, from, next) {
		routeGuardAdmin(to, from, next);
	}

	public async mounted(): Promise<any> {
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.USERS.USERS')}, {title: this.$t('MENU.USERS.EDIT_USER')}]);
		if(this.hasAdminAccess) await dispatchGetUsers(this.$store);
		this.reset();
	}

	public reset() {
		this.email = '';
		this.full_name = '';
		this.is_superuser = false;
		this.is_active = true;
		this.password1 = '';
		this.password2 = '';
		this.isLoading = false;
		this.setPassword = false;
		if (this.user) {
			this.email = this.user.email;
			this.full_name = this.user.full_name;
			this.is_superuser = this.user.is_superuser;
			this.is_active = this.user.is_active;
		}
	}
	private validationMsg(value: any, field: any, feedBack: string): any {
		var name_Pattern = /^[a-zA-Z\u4e00-\u9fa5À-ÿ ]+$/i;
		var email_Pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		switch (feedBack) {
			case 'name':
				if (name_Pattern.test(value) === false) {
					field.error_feedback = this.$t('USERS.ALERT.FORMAT_NAME');
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'email':
				if (email_Pattern.test(value) === false) {
					field.error_feedback = this.$t('USERS.ALERT.FORMAT_EMAIL');
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'password1':
				if (value.length < field.minLength || value.length > field.maxLength) {
					field.error_feedback = this.$t('USERS.ALERT.LIMIT_LENGTH', {min: field.minLength, max: field.maxLength});
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'password2':
				if (value !== this.password1) {
					field.error_feedback = this.$t('USERS.ALERT.CONFIRM_PASSWORD');
					field.is_valid = false;
					break;
				} else if (value.length < field.minLength || value.length > field.maxLength) {
					field.error_feedback = this.$t('USERS.ALERT.LIMIT_LENGTH', {min: field.minLength, max: field.maxLength});
					field.is_valid = false;
					break;
				} else {
					field.is_valid = true;
					field.error_feedback = '';
					break;
				}
			case 'required':
				if (value == '') {
					field.error_feedback = this.$t('USERS.ALERT.REQUIRED_FIELD');
					field.is_valid = false;
					break;
				}
		}
	}
	private getValidList(): void {
		var obj = this.vFields;
		for (var key in obj) {
			if (key === 'name' || key === 'email') {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					this.allValid.push(obj[key].is_valid);
				}
			}
		}
	}
	public async submitUpdatedUser(event: {preventDefault: () => void}) {
		event.preventDefault();
		this.validationMsg(this.user!.full_name, this.vFields.name, 'name');
		this.validationMsg(this.user!.email, this.vFields.email, 'email');
		this.isLoading = true;

		if (await this.validToSubmit) {
			const updateUser: IUserProfileUpdate = {
				full_name: this.full_name,
				email: this.email,
				is_active: this.is_active,
				is_superuser: this.is_superuser,
			};
			if (await this.setPassword) {
				this.validationMsg(this.password1, this.vFields.password1, 'password1');
				this.validationMsg(this.password2, this.vFields.password2, 'password2');
				if (this.vFields.password1.is_valid && this.vFields.password2.is_valid) {
					updateUser.password = this.password2;
				} else {
					this.isLoading = false;
					return;
				}
			}
			try {
				if (this.isSelf) {
					//update Main state if is self
					await dispatchUpdateUserProfile(this.$store, updateUser);
				}
				if (this.hasAdminAccess) {
					//update allusers if is admin
					await dispatchUpdateUser(this.$store, {id: this.user!.id, user: updateUser});
				}
				this.makeToast(this.$t('USERS.ALERT.SUCCESS'), 'success');
				this.$router.back();
			} catch {
				this.makeToast(this.$t('USERS.ALERT.ERROR'), 'danger');
			}
		}
		this.isLoading = false;
		this.allValid = [];
	}
	private cancel() {
		this.$router.push('/admin/users');
	}
}
