
import {Component, Vue} from 'vue-property-decorator';
import {readUserProfile} from '@/core/services/store/main/getters';
import {dispatchUserLogOut} from '@/core/services/store/main/actions';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import KTOffcanvas from '@/assets/js/components/offcanvas.js';

@Component
export default class KTQuickUser extends Vue {
	get mailNotificationIcon(): any {
		return process.env.BASE_URL + 'media/svg/icons/Communication/Mail-notification.svg';
	}
	get notificationIcon(): any {
		return process.env.BASE_URL + 'media/svg/icons/General/Notification2.svg';
	}
	get currentUserPersonalInfo() {
		return readUserProfile(this.$store);
	}
	get role() {
		return this.currentUserPersonalInfo!.is_superuser ? this.$t('USERS.FORM.ADMIN') : this.$t('USERS.FORM.CONSULTANT');
	}

	public mounted() {
		// Init Quick User Panel
		KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
	}

	private onLogout() {
		dispatchUserLogOut(this.$store);
	}
	private closeOffcanvas() {
		new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
	}
}
