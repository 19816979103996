
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {dispatchLogIn} from '@/core/services/store/main/actions';
import {readLoginError} from '@/core/services/store/main/getters';

import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

import KTUtil from '@/assets/js/components/util';

@Component({
	computed: {
		...mapGetters(['layoutConfig'])
	}
})
export default class Login extends Vue {
	[x: string]: any;

	private state: string = 'signin';
	private form: any = {
		username: '',
		password: ''
	};

	get seidorLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.seidor.light-background');
	}

	get pinterviewLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('self.logo.pinterview.horizontal');
	}

	get loginError() {
		return readLoginError(this.$store);
	}

	public mounted() {
		const signin_form = KTUtil.getById('kt_login_signin_form');
		const fv = formValidation(signin_form, {
			fields: {
				username: {
					validators: {
						notEmpty: {
							message: this.$t('AUTH.VALIDATION.REQUIRED_USERNAME')
						},
						emailAddress: {
							message: this.$t('AUTH.VALIDATION.INVALID_EMAIL')
						}
					}
				},
				password: {
					validators: {
						notEmpty: {
							message: this.$t('AUTH.VALIDATION.REQUIRED_PASSWORD')
						}
					}
				}
			},
			plugins: {
				trigger: new Trigger(),
				submitButton: new SubmitButton(),
				bootstrap: new Bootstrap()
			}
		});

		fv.on('core.form.valid', async () => {
			// set spinner to submit button
			const submitButton = <HTMLElement>this.$refs['kt_login_signin_submit'];
			// return if api has been called but not response yet
			if(submitButton.classList.contains('spinner')){
				return;
			}
			submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

			await dispatchLogIn(
				this.$store,
				new URLSearchParams({
					username: this.form.username,
					password: this.form.password
				})
			);

			submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
			this.form.password = '';
		});
	}
}
