
import {api} from '@/api';
import {getIdb, createResource, createOrUpdateResource, clearResource} from '@/core/services/indexedDB.service';
import {Component, Vue, Mixins} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTSubheader from '@/view/layout/subheader/Subheader.vue';
import SearchBanner from '@/view/layout/subheader/SearchBanner.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop.vue';
import Loader from '@/view/content/Loader.vue';
import {ILabel, IQuestion} from '@/interfaces/exam';
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from '@/core/services/store/module/htmlclass.module.js';

import MakeToast from '@/view/content/mixin/MakeToast.vue';

@Component({
	components: {
		KTHeader,
		KTHeaderMobile,
		KTFooter,
		KTSubheader,
		SearchBanner,
		KTScrollTop,
		Loader
	},
	computed: {
		...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig'])
	}
})
export default class Layout extends Mixins(MakeToast) {
	[x: string]: any;
	public vueQuestions = {} as {questions: Array<IQuestion>; amount_of_questions: number};
	public vueLabels: Array<ILabel> = [];
	public vueInterviews: Array<any> = [];
	public async created(): Promise<void> {
		//check for support
		if (!('indexedDB' in window)) {
			this.makeToast(this.$t('INDEXEDDB.NO_SUPPORT'), 'warning');
			return;
		}
	}
	public beforeMount(): void {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	}
	get axiosParams() {
		const params = new URLSearchParams();
		params.append('start_index', '0');
		params.append('end_index', '10');
		return params.toString();
	}
	public async mounted(): Promise<void> {
		// if online, clear indexed DB for new data to fill in. Otherwise keep cache for offline use
		await getIdb.then(async db => {
			if (window.navigator) {
				await clearResource(db, 'questions');
				await clearResource(db, 'labels');
				await clearResource(db, 'interviews');
			} else {
				this.makeToast(this.$t('INDEXEDDB.OFFLINE'), 'danger');
			}
			try {
				await Promise.all([
					api.getAllQuestions({end_index: 10}).then(response => {
						this.vueQuestions = response.data.questions;
						localStorage.setItem('question-amount', response.data.amount_of_questions);
					}),
					api.getLabels().then(response => (this.vueLabels = response.data)),
					api.getInterviews(this.axiosParams).then(response => (this.vueInterviews = response.data.interviews))
				]);
				createOrUpdateResource(db, 'labels', this.vueLabels);
				createOrUpdateResource(db, 'questions', this.vueQuestions);
				createOrUpdateResource(db, 'interviews', this.vueInterviews);
			} catch (error: any) {
				this.makeToast(this.$t('QUESTIONS.ALERT.API_ERROR_MESSAGE'), 'danger');
			}
			this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
		});
	}

	/**
	 * Check if the page loader is enabled
	 * @returns {boolean}
	 */
	get loaderEnabled(): boolean {
		return !/false/.test(this.layoutConfig('loader.type'));
	}

	/**
	 * Check if container width is fluid
	 * @returns {boolean}
	 */
	get contentFluid(): boolean {
		return this.layoutConfig('content.width') === 'fluid';
	}

	/**
	 * Page loader logo image using require() function
	 * @returns {string}
	 */
	get loaderLogo(): any {
		return process.env.BASE_URL + this.layoutConfig('loader.logo');
	}

	/**
	 * Check if the left aside menu is enabled
	 * @returns {boolean}
	 */
	get asideEnabled(): boolean {
		return !!this.layoutConfig('aside.self.display');
	}

	/**
	 * Set the right toolbar display
	 * @returns {boolean}
	 */
	get toolbarDisplay(): boolean {
		// return !!this.layoutConfig("toolbar.display");
		return true;
	}

	/**
	 * Set the subheader display
	 * @returns {boolean}
	 */
	get subheaderDisplay(): boolean {
		return !!this.layoutConfig('subheader.display');
	}
	get subheaderLayout(): string {
		return this.layoutConfig('subheader.layout');
	}
}
