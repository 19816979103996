
	import {Component, Mixins} from 'vue-property-decorator';
	import MakeToast from '@/view/content/mixin/MakeToast.vue';
	import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
	import {api} from '@/api';
	import {getIdb, passResourceToVue, checkResourceNotEmpty} from '@/core/services/indexedDB.service';
	import Multiselect from '@/view/content/Multiselect.vue';
	import {IInterviewProfileCreate, ILabel} from '@/interfaces/exam';
	import KTUtil from "@/assets/js/components/util";
	import KTWizard from "@/assets/js/components/wizard";

	@Component({
		components: {
			Multiselect
		}
	})
	export default class CreateInterview extends Mixins(MakeToast) {
		private name: string = '';
		private email: string = '';
		private amountOfQuestions: number = 30;
		private maxTime: number = 60;
		private labels: any = [];
		private labelSource: ILabel[] = [];
		private isLoading: boolean = false;
		private interviewLink: string ='';
		private copyButtonMessage: string = this.$t('INTERVIEWS.TOOLTIP.COPY_TO_CLIPBOARD');

		private vFields: any = {
			name: {
				is_valid: null,
				error_feedback: ''
			},
			email: {
				is_valid: null,
				error_feedback: ''
			},
			labels: {
				is_valid: null,
				error_feedback: ''
			},
		};
		
		get NotvalidForm() {
			return !Object.values(this.vFields).map(vField => (vField as any).is_valid).every(Boolean)
		}
		get difficulty() {
			const degreeOfDefficulty = Math.floor(this.maxTime *10 / this.amountOfQuestions);
			if (degreeOfDefficulty < 10) {
				return this.$t('INTERVIEWS.FORM.VERY_HARD')
			} else if (degreeOfDefficulty < 15) {
				return this.$t('INTERVIEWS.FORM.HARD')
			} else if(degreeOfDefficulty < 20) {
				return this.$t('INTERVIEWS.FORM.MEDIUM')
			} else {
				return this.$t('INTERVIEWS.FORM.EASY')
			}
		}

		public created(): void {
			if (navigator.onLine) {
				api
					.getLabels()
					.then(response => {
						this.labelSource = response.data;
					})
					.catch(() => {
						this.makeToast(this.$t('QUESTIONS.ALERT.LABEL_GET_ERROR_MESSAGE'), 'danger');
					});
			} else {
				this.makeToast(this.$t('INDEXEDDB.OFFLINE'), 'danger');
				getIdb.then(async db => {
					const checkLabelsNotEmpty = await checkResourceNotEmpty(db, 'labels');
					if (checkLabelsNotEmpty) {
						await passResourceToVue(db, 'labels').then((docs: Array<ILabel>) => {
							this.labelSource = docs;
						});
					}
				});
			}
		}
		public mounted(): any {
			(this.$refs.user_name as HTMLInputElement).focus();
			this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.INTERVIEWS.INTERVIEWS')}, {title: this.$t('MENU.INTERVIEWS.CREATE_INTERVIEW')}]);

			// Initialize form wizard
			const wizard = new KTWizard("kt_wizard_v1", {
				startStep: 1, // initial active step number
				clickableSteps: true // allow step clicking
			});

			// Changed event
			wizard.on("changed", function(/*wizardObj*/) {
	               document.getElementById('Back_Button')!.classList.toggle('d-none');
				setTimeout(() => {
					KTUtil.scrollTop();
				}, 200);
			});
		}

		private removeLabel(option: ILabel): void {
			this.labels.splice(this.labels.indexOf(option), 1);
			this.validationMsg(this.labels, this.vFields.labels, 'labels')
		}
		private validationMsg(value, field, feedBack): void {
			const name_Pattern = /^[\_\-a-zA-ZÀ-ÿ\u4e00-\u9fa5\s]+$/i;
			const email_Pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
			const number_Pattern = /^[0-9]*$/;
			switch (feedBack) {
				case 'name':
					if (!value) {
						field.is_valid = false;
						field.error_feedback = this.$t('INTERVIEWS.ALERT.REQUIRED_FIELD');
					} else if (!name_Pattern.test(value)) {
						field.is_valid = false;
						field.error_feedback = this.$t('INTERVIEWS.ALERT.FORMAT_NAME');
					} else {
						field.is_valid = true;
						field.error_feedback = '';
					}
					break;
				case 'email':
					if (!value) {
						field.is_valid = false;
						field.error_feedback = this.$t('INTERVIEWS.ALERT.REQUIRED_FIELD');
					} else if (!email_Pattern.test(value)) {
						field.is_valid = false;
						field.error_feedback = this.$t('INTERVIEWS.ALERT.FORMAT_EMAIL');
					} else {
						field.is_valid = true;
						field.error_feedback = '';
					}
					break;
				case 'labels':
					if (value.length < 1) {
						field.is_valid = false;
						field.error_feedback = this.$t('INTERVIEWS.ALERT.LABEL_NOSELECT_MESSGE');
					} else {
						field.is_valid = true;
						field.error_feedback = '';
					}
					break;
			}
		}

		public async submitCreateInterview() {
			if (!this.isLoading) {
				this.isLoading = true;
				let interview: IInterviewProfileCreate = {
					name: this.name,
					email: this.email,
					max_time: Number(this.maxTime),
					amount_of_questions: Number(this.amountOfQuestions),
					labels: this.labels.map(element => element.name)
				};
				await api
					.createInterview(interview)
					.then(response => {
						this.makeToast(this.$t('INTERVIEWS.ALERT.CREATE_SUCCESS'), 'success');
	                    let path = `${window.location.href.split(window.location.pathname)[0]}/interviews/${response.data.code}`;
						this.interviewLink = path;
						document.getElementById('Previous_Button')!.innerHTML = this.$t("INTERVIEWS.FORM.CONTINUE_CREATE")
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth'
						});
					})
					.catch(() => {
						this.makeToast(this.$t('INTERVIEWS.ALERT.CREATE_FAIL'), 'danger');
					});

				this.isLoading = false;
			}
		}
		private copyToClipboard() {
			const copyText = this.$refs.interview_link_input as HTMLInputElement;
			copyText.select();
			copyText.setSelectionRange(0, this.interviewLink.length);
			document.execCommand('copy');
			this.copyButtonMessage = this.$t('INTERVIEWS.TOOLTIP.COPIED');
			window.getSelection()!.removeAllRanges();
			copyText.blur();
		}
		private resetTooltipMessage() {
			this.copyButtonMessage = this.$t('INTERVIEWS.TOOLTIP.COPY_TO_CLIPBOARD');
		}
		private back() {
			this.$router.back();
		}
		private resetForm() {
			if(this.interviewLink){
				setTimeout(() => {
				this.name = '';
				this.email = '';
				this.labels = [];
				this.amountOfQuestions = 30;
				this.maxTime = 60;
				this.interviewLink = '';
				this.vFields = {
					name: {
						is_valid: null,
						error_feedback: ''
					},
					email: {
						is_valid: null,
						error_feedback: ''
					},
					labels: {
						is_valid: null,
						error_feedback: ''
					}
				};
				document.getElementById('Previous_Button')!.innerHTML = this.$t("INTERVIEWS.FORM.PREVIOUS")
				}, 100)
			}
		}
	}
