
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {Component, Mixins, Ref} from 'vue-property-decorator';
import {readAdminUsers} from '@/core/services/store/admin/getters';
import {dispatchGetUsers} from '@/core/services/store/admin/actions';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import {readUserProfile} from '@/core/services/store/main/getters';
import {api} from '@/api';
import Pagination from '@/view/content/Pagination.vue';
import swal from 'sweetalert2';

@Component({components: {Pagination}})
export default class UsersList extends Mixins(MakeToast) {
	private fields: Array<any> = [
		{key: 'full_name', label: this.$t('USERS.TABLE.NAME')},
		{key: 'email', label: this.$t('USERS.TABLE.EMAIL')},
		{key: 'is_active', label: this.$t('USERS.TABLE.IS_ACTIVE')},
		{key: 'is_superuser', label: this.$t('USERS.TABLE.IS_SUPERUSER')},
		{key: 'action', label: this.$t('USERS.TABLE.ACTION'), thStyle: {width: '5%'}}
	];

	private pagination: any = {
		currentPage: 1,
		perPage: 10
	};
	@Ref('spinner') spinner!: HTMLElement;

	get users() {
		return readAdminUsers(this.$store);
	}

	public async mounted() {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');
		await dispatchGetUsers(this.$store);
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.USERS.USERS')}]);
	}

	beforeUpdate() {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.remove('spinner');
	}

	private isMe(id: number) {
		return id === readUserProfile(this.$store)!.id;
	}

	public onPageChanged(val: number) {
		this.pagination.currentPage = val;
	}

	public deleteUser(userId, index) {
		let self = this;
		swal
			.fire({
				title: this.$t('SWEETALERT.CONFIRM_DELETE.TITLE'),
				text: this.$t('SWEETALERT.CONFIRM_DELETE.TEXT'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.$t('SWEETALERT.CANCEL_BUTTON_TEXT'),
				confirmButtonText: this.$t('SWEETALERT.CONFIRM_DELETE.CONFIRM_BUTTON_TEXT'),
				heightAuto: false,
				reverseButtons: true
			})
			.then(async result => {
				if (result.isConfirmed) {
					try {
						await api.deleteUser(userId);
						self.users.splice(index, 1);
						this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.SUCCESS_TEXT_DELETE', {name: this.$t('SUBJECT.USER')}), 'success');
					} catch {
						this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.ERROR_TEXT_DELETE', {name: this.$t('SUBJECT.USER')}), 'danger');
					}
				}
			});
	}
}
