
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
})
export default class KTSubheader extends Vue {
  [x: string]: any;

  @Prop()
  private breadcrumbs!: string[];
  @Prop()
  private title!: String;

  /**
   * Check if subheader width is fluid
   */
  get widthFluid(): boolean {
    return this.layoutConfig("subheader.width") === "fluid";
  }

  get subheaderClasses(): string {
    const classes: string[] = [];
    const style = this.layoutConfig("subheader.style");
    if (style) {
      classes.push(style);

      if (style === "solid") {
        classes.push("bg-white");
      }

      if (this.layoutConfig("subheader.fixed")) {
        classes.push("border-top");
      }
    }
    return classes.join(" ");
  }
}
