
import {Component, Emit, Prop, Mixins} from 'vue-property-decorator';
import {ILabel, IQuestion} from '@/interfaces/exam';
import MarkdownToHtml from './mixin/MarkdownToHtml.vue';

interface Label extends ILabel {
	isActive?: boolean;
}

@Component
export default class ResultQuestion extends Mixins(MarkdownToHtml) {
	@Prop() public index!: number;
	@Prop() public propQuestion;
	private question = {
		labels: [],
		timeSpent: 0,
		description: '',
	};

	public propertyGenerator(status: string) {
		switch (status) {
			case 'CORRECT':
				return {class: 'success', text: 'CORRECT'};
			case 'WRONG':
				return {class: 'danger', text: 'WRONG'};
			case 'PARTIALLY':
				return {class: 'warning', text: 'PARTIAL'};
			default:
				return {class: 'dark', text: 'NOT_ANSWERED'};
		}
	}
	public mounted() {
		const questionResult = this.propQuestion;
		let question = questionResult.question;
		let history = questionResult.answer_history;
		let answers = question.answers.map(answer => {
			answer.result = [];
			for (let i = 0; i < history.length; i++) {
				let result: {'chosen': null | boolean; 'correct': null | boolean} = {'chosen': null, 'correct': null};
				if (history[i].answers.includes(answer.id)) {
					result.chosen = true;
					if (answer.is_valid) result.correct = true;
					else result.correct = false;
				} else {
					result.chosen = false;
					if (answer.is_valid) result.correct = false;
					else result.correct = true;
				}
				answer.result.push(result);
			}
			return answer;
		});
		question.timeSpent = questionResult.time_spent;
		question.answerStatus = questionResult.answer_status;
		question.answers = answers;
		question.historyLength = history.length;
		this.question = question;
	}

	public getHistory(answer) {
		let result = {
			"chosen": false,
			"correct": false
		}
		answer.result.slice(0, -1).map( history => {
			if(history.chosen) {
				result = history;
				return;
			}
		})
		return result;
	}
}
