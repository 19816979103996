
import {getIdb, passResourceToVue, checkResourceNotEmpty} from '@/core/services/indexedDB.service';
import {Watch, Component, Mixins, Ref} from 'vue-property-decorator';
import {api} from '@/api';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module.js';
import CustomMultiselect from '@/view/content/Multiselect.vue';
import ListQuestion from '@/view/content/ListQuestion.vue';
import {ILabel, IQuestion} from '@/interfaces/exam';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import Pagination from '@/view/content/Pagination.vue';
import swal from 'sweetalert2';
interface Label extends ILabel {
	name: any;
	isActive?: boolean;
}
type Params = {
	start_index: number | null;
	end_index: number;
	labels?: string;
};

@Component({components: {CustomMultiselect, ListQuestion, Pagination}})
export default class viewQuestions extends Mixins(MakeToast) {
	public readonly perPage: number = 10;
	public currentPage: number = 1;
	public rows: number = 1;
	public seeAnswerOptions: boolean = true;
	public seeQuestionlabels: boolean = true;
	public mainLabels: Array<Label> = [];
	public labels: Array<string> = [];
	public activeLabels: Array<Label> = [];
	public rawQuestions: Array<IQuestion> = [];
	public filteredQuestions: Array<IQuestion> = [];
	public searchLevel: any = '';
	public uploader!:HTMLInputElement;
	public isSaving: boolean = false;
	@Ref('spinner') spinner!: HTMLElement;

	public editQuestion(question: IQuestion) {
		this.$router.push(`/admin/questions/${question.id}/edit`);
	}
	public confirmRemove(questionId: number) {
		let self = this;
		swal
			.fire({
				title: this.$t('SWEETALERT.CONFIRM_DELETE.TITLE'),
				text: this.$t('SWEETALERT.CONFIRM_DELETE.TEXT'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.$t('SWEETALERT.CANCEL_BUTTON_TEXT'),
				confirmButtonText: this.$t('SWEETALERT.CONFIRM_DELETE.CONFIRM_BUTTON_TEXT'),
				heightAuto: false,
				reverseButtons: true
			})
			.then(async result => {
				if (result.isConfirmed) {
					try {
						await self.removeQuestion(questionId);
						this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.SUCCESS_TEXT_DELETE', {name: this.$t('SUBJECT.QUESTION')}), 'success');
					} catch (error: any) {
						if (error.response.status === 406 || 409) {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.QUESTION_DELETE_ERROR_MESSAGE'), 'danger');
						} else {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.ERROR_TEXT_DELETE', {name: this.$t('SUBJECT.QUESTION')}), 'danger');
						}
						let spinner = <HTMLElement>this.$refs.spinner;
						spinner.classList.remove('spinner');
					}
				}
			});
	}
	private async mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.QUESTIONS.QUESTIONS')}]);
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');

		// create an input for file upload
		this.uploader = document.createElement("input");
		this.uploader.type = 'file';
		this.uploader.accept = '.yaml';
		this.uploader.addEventListener('change', this.uploadFile);

		if (!navigator.onLine) {
			this.makeToast(this.$t('INDEXEDDB.OFFLINE'), 'danger');
			getIdb.then(async db => {
				const checkQuesionsNotEmpty = await checkResourceNotEmpty(db, 'questions');
				const checkLabelsNotEmpty = await checkResourceNotEmpty(db, 'labels');
				if (checkQuesionsNotEmpty && checkLabelsNotEmpty) {
					await passResourceToVue(db, 'questions').then((questions: Array<IQuestion>) => {
						this.rawQuestions = questions;
					});
					await passResourceToVue(db, 'labels').then(
						(docs: Array<ILabel>) =>
							(this.mainLabels = docs.map(el => ({
								...el,
								isActive: false
							})))
					);
					this.rows = Number(localStorage.getItem('question-amount')) || 1;
					this.filteredQuestions = this.rawQuestions;
				}
			});
		} else {
			try {
				await Promise.all([
					api.getAllQuestions({end_index: 10}).then(response => {
						this.rawQuestions = response.data.questions;
						this.rows = response.data.amount_of_questions;
					}),
					api.getLabels().then(response => {
						this.mainLabels = response.data.map((el: Array<any>) => ({
							...el,
							isActive: false
						}));
					})
				]);
				this.filteredQuestions = this.rawQuestions;
			} catch (error: any) {
				this.makeToast(this.$t('QUESTIONS.ALERT.API_ERROR_MESSAGE'), 'danger');
			}
		}
		spinner.classList.remove('spinner');
	}
	private destroyed(){
		this.uploader.removeEventListener('change', this.uploadFile);
	}
	public uploadTrigger(){
		this.uploader.click();
	}
	async uploadFile(){
		let formData = new FormData();
		if(!this.uploader!.files!.length){ return; }
		this.isSaving = true;
		try{
			const fileName = `${+new Date()}-questions.yaml`
			formData.append('file', this.uploader!.files![0], fileName);
			await api.uploadQuestions(formData);			
			this.makeToast(this.$t('QUESTIONS.ALERT.QUESTION_UPLOAD_SUCCESS', {file1:this.uploader!.files![0].name, file2:fileName}),'success');
		}catch (error){
			this.makeToast(this.$t('QUESTIONS.ALERT.API_ERROR_MESSAGE'), 'danger');
		}
		this.isSaving = false;
	}

	@Watch('activeLabels', {deep: true})
	onActiveLabelsChanged() {
		let activeNames = this.activeLabels.map(el => el.name);
		this.mainLabels.forEach(label => {
			if (activeNames.includes(label.name)) label.isActive = true;
			else label.isActive = false;
		});
		this.currentPage = 1;
		this.filterQuestions();
	}
	@Watch('searchLevel')
	onSearLevelChanged() {
		this.currentPage = 1;
		this.filterQuestions();
	}
	public onPageChanged(val: number) {
		this.currentPage = val;
		this.filterQuestions();
	}
	public filterQuestions() {
		try {
			let params: Params = {start_index: 10 * (this.currentPage - 1), end_index: 10 * this.currentPage};
			if (this.activeLabels.length) {
				Object.assign(params, {'labels': this.labelString(this.activeLabels)});
			}
			const currentPage = this.currentPage;
			api.getAllQuestions(params).then(response => {
				this.rawQuestions = response.data.questions;
				this.filteredQuestions = response.data.questions;
				this.rows = response.data.amount_of_questions;
			});
		} catch (error: any) {
			this.warnError(error.message);
		}
	}
	public labelString(array: Array<any>) {
		let labelArray = array.map(label => label.name) as Array<string>;
		return labelArray.join(' | ');
	}
	public async removeQuestion(questionId: number) {
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');
		await api.deleteQuestion(questionId);
		await api.getAllQuestions().then(response => (this.rawQuestions = response.data.questions)), spinner.classList.remove('spinner');
		const idx = this.filteredQuestions.findIndex(question => question.id === questionId);
		this.rows = this.rows - 1;
		this.filteredQuestions.splice(idx, 1);
		this.filteredQuestions = [...this.filteredQuestions];
	}
	public disactiveLable(disactivedLabel: string) {
		let activeNames: Array<string> = [];
		this.activeLabels.forEach((label, idx) => {
			if (label.name === disactivedLabel) {
				this.activeLabels.splice(idx, 1);
				return;
			} else activeNames.push(label.name);
		});
		this.mainLabels.forEach(label => {
			if (activeNames.indexOf(label.name) > 0) {
				label.isActive === true;
			} else {
				label.isActive === false;
			}
		});
	}
	public warnError(errorMsg: string): void {
		this.makeToast(errorMsg, 'danger');
	}
}
