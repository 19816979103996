
import {Component, Ref, Mixins, Watch} from 'vue-property-decorator';
import {api} from '@/api';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import {ILabel} from '@/interfaces/exam';

@Component
export default class createLabel extends Mixins(MakeToast) {
	private input: ILabel = {
		name: '',
		description: '',
		color: '#4682B4'
	};
	private label: any = {};
	private validation: any = {
		is_valid: null,
		minLength: 3,
		maxLength: 50,
		error_feedback: ''
	};
	private isLoading = false;
	private colorPalette: any = [
		{colorName: this.$t('LABELS.COLORPALETTE.GREEN_CYAN'), colorNum: '#009966'},
		{colorName: this.$t('LABELS.COLORPALETTE.DARK_SEA_GREEN'), colorNum: '#8fbc8f'},
		{colorName: this.$t('LABELS.COLORPALETTE.GREEN_SCREEN'), colorNum: '#00b140'},
		{colorName: this.$t('LABELS.COLORPALETTE.BLUE_GRAY'), colorNum: '#6699cc'},
		{colorName: this.$t('LABELS.COLORPALETTE.BLUE'), colorNum: '#0000ff'},
		{colorName: this.$t('LABELS.COLORPALETTE.DARL_VIOLET'), colorNum: '#9400d3'},
		{colorName: this.$t('LABELS.COLORPALETTE.ROSE_RED'), colorNum: '#c21e56'},
		{colorName: this.$t('LABELS.COLORPALETTE.MAGENTA_PINK'), colorNum: '#cc338b'},
		{colorName: this.$t('LABELS.COLORPALETTE.TITANIUM_YELLOW'), colorNum: '#eee600'},
		{colorName: this.$t('LABELS.COLORPALETTE.CARROT_ORANGE'), colorNum: '#ed9121'}
	];
	private mapLabelName: any = [];
	private firstColor: string = ''
	private firstDes: string = ''
	private colorUpdated: boolean = false
	private desUpdated: boolean = false
	@Ref() readonly labelTitle!: HTMLInputElement;
	
	@Watch('input.color')
	onColorChange(val){
		if (val !== this.firstColor){
			this.colorUpdated = true
		}else{
			this.colorUpdated = false
		}		
	}

	@Watch('input.description')
	onDesChange(val){
		if (val !== this.firstDes){
			this.desUpdated = true
		}else{
			this.desUpdated = false
		}		
	}

	public async created(): Promise<void> {
		if(this.$route.params.id){
			try {
			this.label = this.$route.params.label;	
			const labels: Array<ILabel> = (await api.getLabels()).data;
			this.mapLabelName = labels.map(label => label.name.toUpperCase());
			if (!this.label) {
				this.label = labels.filter(label => label.id == Number(this.$route.params.id))[0];
			}
			this.input = this.label;
			this.firstColor = this.label.color
			this.firstDes = this.label.description
		} catch {
			this.$router.back();
		}
		}
	}

	public mounted(): void {
		this.labelTitle.focus();
		this.getLabels();
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.LABELS.LABELS')}, {title: !this.$route.params.id?this.$t('MENU.LABELS.CREATE_LABEL'):this.$t('MENU.LABELS.EDIT_LABEL')}]);
	}

	get isCreateModeElseEditMode() {
		return !this.$route.params.id;
	}

	private colorPicker(color): void {
		const selectedColor = color.colorNum;
		this.input.color = selectedColor;
	}

	private clearAll(): void {
		this.input.name = '';
		this.input.description = '';
		this.input.color = '#4682B4';
		this.isLoading = false;
		this.validation.is_valid = null;
	}

	private getLabels(): any {
		api
			.getLabels()
			.then(response => {
				const labels = response.data;
				this.mapLabelName = labels.map(label => label.name.toUpperCase());
			})
			.catch(err => err);
	}

	private validationMsg(value, feedBack): any {
		var regexPattern = /^(?:[a-zA-Z]+_{0,1})+[a-zA-Z]+$/;
		var inputName = value.toUpperCase();
		switch (feedBack) {
			case 'formFeedBack':
				if (value.length < this.validation.minLength || value.length > this.validation.maxLength) {
					this.validation.error_feedback = this.$t('LABELS.ALERT.LIMIT_LENGTH', {min: this.validation.minLength, max: this.validation.maxLength});
					this.validation.is_valid = false;
				} else if (regexPattern.test(value) === false) {
					this.validation.error_feedback = this.$t('LABELS.ALERT.BAN_CHARACTER');
					this.validation.is_valid = false;
				} else if (this.mapLabelName.includes(inputName)) {
					this.validation.error_feedback = this.$t('LABELS.ALERT.BAN_DUPLICATED');
					this.validation.is_valid = false;
				} else {
					this.validation.is_valid = true;
					this.validation.error_feedback = '';
				}
				this.isLoading = false;
				break;
			case 'serverErrorToast':
				if (value === 400) {
					this.makeToast(this.$t('LABELS.ALERT.BAN_DUPLICATED'));
				} else {
					this.makeToast(this.$t('LABELS.ALERT.SERVER_ERROR'), 'danger');
				}
				this.isLoading = false;
				break;
		}
	}

	private onSubmit(event: Event): void {
		event.preventDefault();
		this.isLoading = true;
		const labelId = this.label.id;
		const submitData = {
			name: this.input.name.toUpperCase(),
			description: this.input.description,
			color: this.input.color
		};
		if(this.isCreateModeElseEditMode){
			//send create label api
			api
			.createLabel(submitData)
			.then(() => {
				this.makeToast(this.$t('LABELS.ALERT.SUCCESS'), 'success');
				this.clearAll();
				this.$router.replace('/admin/labels');
			})
			.catch(err => {
				this.validationMsg(err.response.status, 'serverErrorToast');
			});
		} else {
			//send update label api
			api
			.putLabel(labelId, submitData)
			.then(() => {
				this.makeToast(this.$t('LABELS.ALERT.SUCCESS'), 'success');
				this.isLoading = false;
				this.$router.replace('/admin/labels');
			})
			.catch(err => this.validationMsg(err.response.status, 'serverErrorToast'));
		}
	}
}
