
import router from '@/router';
import {api} from '@/api';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CustomMultiselect from '@/view/content/Multiselect.vue';
import {mapGetters} from 'vuex';
import eventBus from '@/assets/js/components/eventBus';
@Component({
	components: {CustomMultiselect},
	computed: {
		...mapGetters(['layoutConfig'])
	}
})
export default class SearchBanner extends Vue {
	[x: string]: any;
	public searchString = null;
	public searchLevel = null;
	public searchLevelOptions = [
		{value: null, text: this.$t('INTERVIEWS.FORM.OUTCOME_OF_EXAM')},
		{value: 90, text: '> 90'},
		{value: 80, text: '> 80'},
		{value: 60, text: '> 60'},
		{value: 30, text: '> 30'}
	];
	public mainLabels: Array<any> = [];
	public activeLabels: Array<any> = [];
	public callBusSearch() {
		const searchBannerParams = {
			'string': this.searchString ? this.searchString : undefined,
			'score': this.searchLevel ? this.searchLevel : undefined,
			'label': this.activeLabels.length ? this.labelString(this.activeLabels) : undefined
		};
		eventBus.$emit('send-params', searchBannerParams);
	}
	public disactiveLable(disactivedLabel: string) {
		let activeNames: Array<string> = [];
		this.activeLabels.forEach((label, idx) => {
			if (label.name === disactivedLabel) {
				this.activeLabels.splice(idx, 1);
				return;
			} else activeNames.push(label.name);
		});
		this.mainLabels.forEach(label => {
			if (activeNames.indexOf(label.name) > 0) {
				label.isActive === true;
			} else {
				label.isActive === false;
			}
		});
	}
	@Watch('activeLabels')
	onActiveLabelsChanged() {
		let activeNames = this.activeLabels.map(el => el.name);
		this.mainLabels.forEach(label => {
			if (activeNames.includes(label.name)) label.isActive = true;
			else label.isActive = false;
		});
	}
	public labelString(array: Array<any>) {
		let labelArray = array.map(label => label.name) as Array<string>;
		return labelArray.join(' | ');
	}
	public gotoCreateInterview() {
		router.push({name: 'create-interview'});
	}
	/**
	 * Check if subheader width is fluid
	 */
	get widthFluid(): boolean {
		return this.layoutConfig('subheader.width') === 'fluid';
	}
	public mounted() {
		api.getLabels().then(response => {
			this.mainLabels = response.data.map(el => ({
				...el,
				isActive: false
			}));
		});
	}
}
