import { render, staticRenderFns } from "./ViewUsers.vue?vue&type=template&id=6cc74baa&scoped=true&"
import script from "./ViewUsers.vue?vue&type=script&lang=ts&"
export * from "./ViewUsers.vue?vue&type=script&lang=ts&"
import style0 from "./ViewUsers.vue?vue&type=style&index=0&id=6cc74baa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc74baa",
  null
  
)

export default component.exports