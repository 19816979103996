
	import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
	import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
	import {api} from '@/api';
	import {getIdb, passResourceToVue, checkResourceNotEmpty} from '@/core/services/indexedDB.service';
	import QuestionAnswer from '@/view/content/QuestionAnswer.vue';
	import Multiselect from '@/view/content/Multiselect.vue';
	import {IQuestionCreate, IAnswer, ILabel} from '@/interfaces/exam';
	import MakeToast from '@/view/content/mixin/MakeToast.vue';
	import '@/core/plugins/mavonEditor';

		@Component({
			components: {
				QuestionAnswer,
				Multiselect
			}
		})
		export default class CreateQuestion extends Mixins(MakeToast) {
			readonly LABEL_MAXIMUM_LENGTH: number = 214;
			readonly ANSWER_MAXIMUM_NUM: number = 6;
			readonly ANSWER_MINIMUM_NUM: number = 2;
			private aif: HTMLElement | undefined = undefined;
			private lif: HTMLElement | undefined = undefined;

			private questionId: string = '';
			private description: string = '';
			private answers: IAnswer[] = [];
			private labels: any = [];
			private labelSource: ILabel[] = [];
			private answerCount: any = [];
			private componentKey: number = 0;
			private markdownOption = {
				bold: true,
				italic: true,
				header: true,
				underline: true,
				strikethrough: true,
				mark: true,
				superscript: true,
				subscript: true,
				quote: true,
				ol: true,
				ul: true,
				imagelink: true,
				code: true,
				table: true,
				fullscreen: true,
				htmlcode: true,
				help: true,
				undo: true,
				redo: true,
				trash: true,
				alignleft: true,
				aligncenter: true,
				alignright: true,
				subfield: true,
				preview: true
			};

			get isCreateModeElseEditMode() {
				return !this.$route.params.id;
			}

			public created() {
				this.questionId = this.$route.params.id
				this.init();
			}

			private init() {

				if(this.isCreateModeElseEditMode){
					this.addAnswer();
					this.addAnswer();
				}else {
					api.getQuestion(this.questionId)
					.then(response =>{
						const question = response.data;
						this.description = question.description;
						this.answerCount = question.answers;
						this.labels = question.labels;
					})
					.catch(() => {
						this.back();
					})
				}

				//get Labels
				try {
					if (!navigator.onLine) {
						this.makeToast(this.$t('INDEXEDDB.OFFLINE'), 'danger');
						getIdb.then(async db => {
							const checkLabelsNotEmpty = await checkResourceNotEmpty(db, 'labels');
							if (checkLabelsNotEmpty) {
								await passResourceToVue(db, 'labels').then((docs: Array<ILabel>) => {
									this.labelSource = docs;
								});
							}
						});
					} else
						api.getLabels().then(response => {
							this.labelSource = response.data;
						});
				} catch {
					this.makeToast(this.$t('QUESTIONS.ALERT.LABEL_GET_ERROR_MESSAGE'), 'danger');
				}
			}

			public mounted() {
				if (!this.$route.params.id) {
					this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.QUESTIONS.QUESTIONS')}, {title: this.$t('MENU.QUESTIONS.CREATE_QUESTION')}]);
				} else {
					this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.QUESTIONS.QUESTIONS')}, {title: this.$t('MENU.QUESTIONS.EDIT_QUESTIONS')}]);
				}
				this.aif = <HTMLElement>this.$refs.aif;
				this.lif = <HTMLElement>this.$refs.lif;
			}

			private addAnswer(): void {
				if (this.answerCount.length >= this.ANSWER_MAXIMUM_NUM) {
					document.getElementById('AnswersInput')!.classList.add('is-invalid');
					this.aif!.innerHTML = this.$t('QUESTIONS.ALERT.ANSWERS_MAXIMUM_MESSAGE');
					return;
				}
				this.answerCount.push({code: this.getComponentKey()});
				this.answers.push({
					description: '',
					is_valid: false
				});
			}
			private removeAnswer(index: number): void {
				if (this.answerCount.length <= this.ANSWER_MINIMUM_NUM) {
					document.getElementById('AnswersInput')!.classList.add('is-invalid');
					this.aif!.innerHTML = this.$t('QUESTIONS.ALERT.ANSWERS_MINIMUM_MESSAGE');
					return;
				}
				this.answerCount.splice(index, 1);
				this.answers.splice(index, 1);
			}
			private updateAnswers(value: any): void {
				if (!value) {
					return;
				}
				this.$set(this.answers, value.index, value.answer);
			}
			private getComponentKey(): number {
				return this.componentKey++;
			}

			private addLabel(newLabel: string): void {
				const rule = /^[A-Za-z_]+$/;
				const labelInput = document.getElementById('LabelsInput')!;
				if (!rule.test(newLabel)) {
					labelInput.classList.add('is-invalid');
					this.lif!.innerHTML = this.$t('QUESTIONS.ALERT.LABEL_NOVALID_MESSGE');
					return;
				}
				if (newLabel.length >= this.LABEL_MAXIMUM_LENGTH) {
					labelInput.classList.add('is-invalid');
					this.lif!.innerHTML = this.$t('QUESTIONS.ALERT.LABEL_MAXIMUM_MESSGE');
					return;
				}
				this.labels.push({
					name: newLabel,
					color: '#009A17'
				});
			}
			private removeLabel(option: ILabel): void {
				this.labels.splice(this.labels.indexOf(option), 1);
			}

			private async submit(): Promise<void> {
				if (!this.isValidForm()) {
					return;
				}

				let spinner = <HTMLElement>this.$refs.spinner;
				// return if api has been called
				if (spinner.classList.contains('spinner-border')) {
					return;
				}
				spinner.classList.add('spinner-border', 'spinner-border-sm');

				let question: IQuestionCreate = {
					description: this.description,
					answers: this.answers,
					labels: this.labels.map((e: any) => e.name),
				};

				if(this.isCreateModeElseEditMode){
					//Create question with Api
					await api
						.createQuestion([question])
						.then(() => {
							this.clearAll();
							this.makeToast(this.$t('QUESTIONS.ALERT.QUESTION_CREATE_MESSAGE'), 'success');
						})
						.catch(() => {
							this.makeToast(this.$t('QUESTIONS.ALERT.QUESTION_CREATE_ERROR_MESSAGE'), 'danger');
						});
				}else {
					//Update question with Api
					await api
						.updateQuestion(this.questionId, question)
						.then(() => {
							this.makeToast(this.$t('QUESTIONS.ALERT.QUESTION_UPDATE_MESSAGE'), 'success');
						})
						.catch(() => {
							this.makeToast(this.$t('QUESTIONS.ALERT.QUESTION_UPDATE_ERROR_MESSAGE'), 'danger');
						});
				}

				spinner.classList.remove('spinner-border', 'spinner-border-sm');
			}
			private isValidForm(): boolean {
				let hasQuestionDescription = (): boolean => {
					const descriptionInput = document.getElementById('DescriptionInput')!;
					if (this.description !== '') {
						descriptionInput.classList.remove('is-invalid');
						return true;
					}
					descriptionInput.classList.add('is-invalid');
					return false;
				};
				let hasAnswersDescription = (): boolean => {
					if (this.answers.every((e: IAnswer) => e.description !== '' && e.description)) {
						return true;
					}
					this.notifyWhichAnswerIsNotValid();
					return false;
				};
				let hasValidAnswer = (): boolean => {
					const answerInput = document.getElementById('AnswersInput')!;
					if (this.answers.some((e: IAnswer) => e.is_valid === true)) {
						answerInput.classList.remove('is-invalid');
						return true;
					}
					answerInput.classList.add('is-invalid');
					this.aif!.innerHTML = this.$t('QUESTIONS.ALERT.ANSWERS_NOVALID_MESSAGE');
					return false;
				};
				let hasLabels = (): boolean => {
					const labelsInput = document.getElementById('LabelsInput')!;
					if (this.labels.length) {
						labelsInput.classList.remove('is-invalid');
						return true;
					}
					labelsInput.classList.add('is-invalid');
					this.lif!.innerHTML = this.$t('QUESTIONS.ALERT.LABEL_NOSELECT_MESSGE');
					return false;
				};

				const verifyResults = Array<Boolean>();
				verifyResults.push(hasQuestionDescription())
				verifyResults.push(hasAnswersDescription())
				verifyResults.push(hasValidAnswer())
				verifyResults.push(hasLabels())
				
				return verifyResults.every(Boolean);
			}
			private async notifyWhichAnswerIsNotValid(): Promise<void> {
				await this.answerCount.splice(0, 0, {code: this.getComponentKey()});
				this.answerCount.splice(0, 1);
			}
			private clearAll(): void {
				this.description = '';
				this.answers = [];
				this.labels = [];
				this.answerCount = [];
				this.init();
			}
			private back() {
				this.$router.back();
			}
		}
