import { InterviewState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IInterview } from '@/interfaces/exam';

export const getters = {
    interviewCode: (state: InterviewState): string => state.interviewCode,
    interview: (state: InterviewState): IInterview | null => state.interview,
    interviewLastSubmitTime: (state: InterviewState): string => state.lastSubmitTime
}

const { read } = getStoreAccessors<InterviewState, State>('');

export const readInterviewCode = read(getters.interviewCode);
export const readInterview = read(getters.interview);
export const readInterviewLastSubmitTime = read(getters.interviewLastSubmitTime);
