
import {getIdb, passResourceToVue, checkResourceNotEmpty} from '@/core/services/indexedDB.service';
import {Component, Ref, Mixins} from 'vue-property-decorator';
import {api} from '@/api';
import MakeToast from '@/view/content/mixin/MakeToast.vue';
import {SET_BREADCRUMB} from '@/core/services/store/module/breadcrumbs.module';
import {ILabel} from '@/interfaces/exam';
import Pagination from '@/view/content/Pagination.vue';
import swal from 'sweetalert2';

@Component({components: {Pagination}})
export default class LabelsList extends Mixins(MakeToast) {
	private labels: ILabel[] = [];
	private fields: Array<any> = [
		{key: 'name', label: this.$t('LABELS.TABLE.NAME'), thStyle: {width: '30%'}},
		{key: 'description', label: this.$t('LABELS.TABLE.DESCRIPTION'), thStyle: {width: '65%'}},
		{key: 'action', label: this.$t('LABELS.TABLE.ACTION'), thStyle: {width: '5%'}}
	];
	private pagination = {
		currentPage: 1,
		perPage: 10
	};
	@Ref('spinner') spinner!: HTMLElement;

	public created(): void {
		if (!navigator.onLine) {
			getIdb.then(async db => {
				const checkLabelsNotEmpty = await checkResourceNotEmpty(db, 'labels');
				if (checkLabelsNotEmpty) {
					await passResourceToVue(db, 'labels')
						.then((docs: Array<ILabel>) => {
							this.labels = docs;
							this.labels.forEach((o, i) => {
								this.$set(this.labels[i], 'popoverShow', false);
							});
						})
						.catch(() => this.makeToast(this.$t('LABELS.ALERT.SERVER_ERROR'), 'danger'));
				} else {
					this.makeToast(this.$t('INDEXEDDB.OFFLINE'), 'danger');
				}
			});
		} else {
			this.getAllLabels(); // call api if online
		}
	}
	public async mounted(): Promise<void> {
		this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.LABELS.LABELS')}]);
		let spinner = <HTMLElement>this.$refs.spinner;
		spinner.classList.add('spinner');
	}

	public onPageChanged(val: number) {
		this.pagination.currentPage = val;
	}

	private getAllLabels(): void {
		api
			.getLabels()
			.then(response => {
				this.labels = response.data;
				this.labels.forEach((o, i) => {
					this.$set(this.labels[i], 'popoverShow', false);
				});
				let spinner = <HTMLElement>this.$refs.spinner;
				spinner.classList.remove('spinner');
			})
			.catch(() => this.makeToast(this.$t('LABELS.ALERT.SERVER_ERROR'), 'danger'));
	}

	private deleteLabel(label, index): void {
		let self = this;
		swal
			.fire({
				title: this.$t('SWEETALERT.CONFIRM_DELETE.TITLE'),
				text: this.$t('SWEETALERT.CONFIRM_DELETE.TEXT'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.$t('SWEETALERT.CANCEL_BUTTON_TEXT'),
				confirmButtonText: this.$t('SWEETALERT.CONFIRM_DELETE.CONFIRM_BUTTON_TEXT'),
				heightAuto: false,
				reverseButtons: true
			})
			.then(async result => {
				if (result.isConfirmed) {
					try {
						await api.deleteLabel(label.id);
						self.labels.splice(index, 1);
						this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.SUCCESS_TEXT_DELETE', {name: this.$t('SUBJECT.LABEL')}), 'success');
					} catch (error: any) {
						if (error.response.status === 409) {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.LABEL_DELETE_ERROR_MESSAGE'), 'danger');
						} else {
							this.makeToast(this.$t('SWEETALERT.CONFIRM_DELETE.ERROR_TEXT_DELETE', {name: this.$t('SUBJECT.LABEL')}), 'danger');
						}
					}
				}
			});
	}
}
